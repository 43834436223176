body {
    font-family: 'Inter', sans-serif;
    background-color: rgb(244, 244, 244);
    font-size: 14px;
}

a {
    transition: all .10s linear;
    font-weight: normal;
}

label {
    font-size: 14px;
}

button,
button:active,
button:focus {
    outline: none !important;
}

input,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

@font-face {
    font-family: 'ALS Rubl';
    font-style: normal;
    font-weight: normal;
    src: local('ALS Rubl'), url('../fonts/rouble.woff') format('woff');
}

.btn {
    font-size: 14px;
}

hr {
    height: 2px;
    background-color: #F7F8FA;
    color: #F7F8FA;
    border: none;
}

.form-control {
    /* border: 1px solid #f7f8fa !important; */
    border: 1px solid #ced4da !important;
    font-size: 14px !important;
    padding: 9px 10px;
    height: auto !important;
    border-radius: 10px;
    background-color: #f7f8fa;
}

.form-control:focus {
    border-color: #007bff !important;
    border-width: 1px;
    /* box-shadow: none !important; */
    box-shadow: 0 0 0 0.1rem rgb(13 110 253 / 25%);
}

.form-control.has-error {
    border-color: #dc3545;
}

.top-toolbar {
    box-shadow: 0 0 30px rgba(0, 0, 0, .15);
    background-color: #fff;
    height: 65px;
    padding-left: 15px;
    padding-right: 15px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 10;
}

.top-toolbar-container {
    margin: 0 auto;
    max-width: 1200px;
}

.top-toolbar>div,
.top-toolbar>div>div {
    height: 100%;
}

.h-logo-mobile {
    height: 100%;
    display: inline-flex;
    align-items: center;
}

.h-logo-mobile img {
    max-height: 40px;
    margin-top: auto;
    margin-bottom: auto;
}

.h-logo {
    height: 100%;
    display: inline-flex;
    align-items: center;
}

.h-logo img {
    max-height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    width: auto;
}

.f-logo img {
    max-height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    width: auto;
}

.f-logo {
    margin-bottom: 20px;
    display: block;
}

.toolbar-dropdown>span {
    cursor: pointer;
}

.toolbar-dropdown {
    display: inline-flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
}

.toolbar-dropdown .la-angle-down {
    margin-left: 7px;
}

.toolbar-dropdown-box {
    position: absolute;
    top: 0px;
    background-color: white;
    box-shadow: 0 6px 32px #5e5c5c24;
    padding: 15px;
    border-radius: 10px;
    display: none;
    overflow: hidden;
    border: 1px solid transparent;
}

.toolbar-dropdown-active {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
    text-decoration: none;
    color: black;
}

.beboss-head__drop-down-block {
    background-color: #fff;
    box-shadow: 0 0 30px rgba(0, 0, 0, .15);
}

.width-300 {
    width: 300px;
}

.width-200 {
    width: 200px;
}

.width-250 {
    width: 250px;
}

.width-180 {
    width: 180px;
}

.width-400 {
    width: 400px;
}

.left-0 {
    left: 0px;
    text-align: left;
}

.right-0 {
    right: 0px;
    text-align: left;
}

.toolbar-city-list a {
    display: block;
    border-radius: 5px;
    padding: 8px 10px;
    color: black;
    text-decoration: none;
}

.toolbar-city-list a:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.toolbar-category-list a {
    display: block;
    border-radius: 5px;
    padding: 8px 10px;
    color: black;
    text-decoration: none;
}

.toolbar-category-list a:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.toolbar-link {
    height: 100%;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    color: #1c1c1c;
    vertical-align: bottom;
    cursor: pointer;
    transition: all .10s linear;
    text-align: center;
}

.icon-link {
    display: inline-block;
    font-size: 21px;
}

.link-add-active {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.index-search {
    position: relative;
}

.index-search input {
    position: relative;
    font-size: 18px;
    padding-left: 50px;
    transition: border-color .15s linear, box-shadow .15s linear;
    border: 1px solid #ccc;
    border-radius: 6px;
    z-index: 1;
    height: 50px;
    width: 100%;
}

.index-search i {
    position: absolute;
    left: 18px;
    top: 13px;
    font-size: 25px;
    color: #ccc;
    z-index: 2;
}

.index-search button {
    height: 50px;
    width: 100%;
}

.shape1 {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 250px;
    height: 250px;
    background: url(../../images/shape4.png);
    display: block;
    z-index: 0;
}

.shape2 {
    position: absolute;
    top: -80px;
    left: -80px;
    width: 300px;
    height: 300px;
    background: url(../../images/shape2.png);
    display: block;
    z-index: 0;
}

.shape3 {
    position: absolute;
    top: 220px;
    left: 300px;
    width: 100px;
    height: 54px;
    background: url(../../images/shape3.png);
    display: block;
    z-index: 0;
}

.shape4 {
    position: absolute;
    top: 250px;
    right: 350px;
    width: 50px;
    height: 58px;
    background: url(../../images/shape1.png);
    display: block;
    z-index: 0;
}

.shape5 {
    position: absolute;
    top: 90px;
    left: 50%;
    width: 60px;
    height: 47px;
    background: url(../../images/shape5.png);
    display: block;
    z-index: 0;
}

.toolbar-icon-list a {
    display: block;
    border-radius: 5px;
    padding: 8px 10px;
    color: black;
    text-decoration: none;
    position: relative;
}

.toolbar-icon-list a:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.toolbar-icon-list i {
    font-size: 22px;
    display: inline-block;
    color: gray;
    margin-right: 3px;
}

.toolbar-dropdown-hover:hover .toolbar-dropdown-box {
    display: block;
}

.item-grid-plug .la-crown {
    font-size: 41px;
}

.item-grid-plug {
    background-color: white;
    border-radius: 10px;
    display: block;
    text-align: center;
    margin: 10px;
    -webkit-transition: all 150ms ease-in-out;
    -moz-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
    overflow: hidden;
    position: relative;
}

.item-grid-plug a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 305px;
    font-weight: bold;
}

.item-grid {
    background-color: transparent;
    display: block;
    margin: 10px;
    -webkit-transition: all 150ms ease-in-out;
    -moz-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
    position: relative;
}

.toolbar-category-list img {
    margin-right: 5px;
}

.item-labels {
    position: absolute;
    left: 10px;
    top: 10px;
    display: inline-block;
    text-align: left;
    z-index: 5;
}

.item-labels i {
    font-size: 18px;
}

.item-labels>div {
    display: inline-flex;
    margin-right: 5px;
    font-size: 15px;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    margin-bottom: 5px;
    align-items: center;
    justify-content: center;
}

.item-auction span {
    font-size: 13px;
}

.item-auction {
    color: #fff;
    background: #EDFF61;
    background: -webkit-linear-gradient(top left, #EDFF61, #F96F2E);
    background: -moz-linear-gradient(top left, #EDFF61, #F96F2E);
    background: linear-gradient(to bottom right, #EDFF61, #F96F2E);
}

.item-vip {
    color: #fff;
    background: #7E44FA;
    background: -webkit-linear-gradient(top left, #7E44FA, #5927C3);
    background: -moz-linear-gradient(top left, #7E44FA, #5927C3);
    background: linear-gradient(to bottom right, #7E44FA, #5927C3);
}

.item-booking {
    color: #fff;
    background: #03a9f4;
    background: -webkit-linear-gradient(top left, #3498db, #03a9f4);
    background: -moz-linear-gradient(top left, #3498db, #03a9f4);
    background: linear-gradient(to bottom right, #3498db, #03a9f4);
}

.main-search-results-item-vip {
    color: #fff;
    background: #EDFF61;
    background: -webkit-linear-gradient(top left, #EDFF61, #F96F2E);
    background: -moz-linear-gradient(top left, #EDFF61, #F96F2E);
    background: linear-gradient(to bottom right, #EDFF61, #F96F2E);
    display: inline-block;
    font-size: 9px;
    padding: 2px 6px;
    border-radius: 5px;
    margin-left: 5px;
    vertical-align: text-top;
}

.item-grid:hover {
    text-decoration: none;
}

.ads-highlight {
    background-color: #fffcbd !important;
    border-radius: 10px;
    overflow: hidden;
}

.ads-highlight .item-grid-info {
    padding: 10px;
    ;
}

.ads-highlight .item-grid-img {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.item-grid-img {
    height: 180px;
    overflow: hidden;
    position: relative;
    display: block;
    text-align: center;
    background-color: #F7F8FA;
    border-radius: 10px;
}

.item-grid-img img {
    height: 100%;
}

.item-grid-img a {
    height: 180px;
    text-decoration: none;
}

.item-grid-info {
    padding: 15px 0;
    background-color: transparent;
    position: relative;
    word-break: break-word;
}

.item-grid-price-now {
    font-size: 17px;
    color: black;
    font-weight: bold;
    display: inline-block;
    margin-right: 10px;
}

.item-grid-price-old {
    font-size: 15px;
    color: #ef4c4c;
    font-weight: bold;
    display: inline-block;
    text-decoration: line-through;
}

.item-grid-info .item-grid-info-price {
    font-size: 18px;
    color: black;
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.item-grid-info a {
    font-size: 14px;
    margin-bottom: 0px;
    color: black;
    display: block;
    font-weight: 500;
}

.item-grid-city,
.item-grid-date {
    font-size: 14px;
    display: block;
    margin-top: 5px;
    color: #999999;
}

.item-grid-count-photo {
    color: white;
    display: inline-block;
    padding: 2px 8px;
    background-color: rgba(0, 0, 0, 0.49019607843137253);
    position: absolute;
    left: 10px;
    bottom: 10px;
    font-size: 12px !important;
    border-radius: 5px;
    z-index: 5;
    vertical-align: text-bottom;
}

.item-grid-count-photo i {
    font-size: 14px;
    margin-right: 3px;
}

.item-grid-favorite {
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
    padding: 3px 10px;
}

.item-grid-favorite i.ion-ios-heart-outline {
    font-size: 26px;
    color: white;
}

.item-grid-favorite i.ion-ios-heart {
    font-size: 26px;
    color: rgb(247, 80, 89);
}

.item-list-favorite {
    cursor: pointer;
    font-size: 24px;
}

.ad-view-favorite {
    cursor: pointer;
    font-size: 28px;
    display: inline-block;
    margin-left: 10px;
}

.item-list-favorite i.ion-ios-heart-outline,
.ad-view-favorite i.ion-ios-heart-outline,
.map-search-offer-content-button i.ion-ios-heart-outline {
    color: rgb(51, 51, 51);
}

.item-list-favorite i.ion-ios-heart,
.ad-view-favorite i.ion-ios-heart,
.map-search-offer-content-button i.ion-ios-heart {
    color: rgb(247, 80, 89);
}

.mt25 {
    margin-top: 25px;
}

.mt50 {
    margin-top: 50px;
}

.mt80 {
    margin-top: 80px;
}

.mb15 {
    margin-bottom: 15px;
}

.mt5 {
    margin-top: 5px;
}

.bottom-border {
    border-bottom: 2px solid;
    border-color: green;
}

.toolbar-button-box {
    display: inline-flex;
    align-items: center;
    height: 100%;
}

.vip-button {
    display: block;
    color: white;
    border-radius: 25px;
    font-weight: bold;
    padding: 3px 13px;

    background: #EDFF61;
    background: -webkit-linear-gradient(top left, #EDFF61, #F96F2E);
    background: -moz-linear-gradient(top left, #EDFF61, #F96F2E);
    background: linear-gradient(to bottom right, #EDFF61, #F96F2E);

}

.fix-calling-add {
    text-align: center;
    padding-top: 100px;
    position: relative;
    z-index: 5;
}

.fix-calling-add h1 {
    text-align: center;
    font-weight: 700;
    font-size: 40px;
}

.fix-calling-add p {
    font-size: 22px;
}

.fix-calling-add a {
    width: 310px;
    height: 60px;
    line-height: 60px;
    font-size: 24px;
    border-radius: 32px;
    background: #fe304b;
    display: block;
    color: #fff;
    margin: 30px auto;
    transition: all .3s ease;
}

.h1title {
    font-weight: 700;
    font-size: 26px;
    color: black;
}

.h1title a {
    color: black;
}

.mb25 {
    margin-bottom: 25px;
}

.mb35 {
    margin-bottom: 35px;
}

.big-category-image {
    position: absolute;
    top: 5px;
    left: 0px;
    display: block;
    width: 100%;
    height: calc(100% - 32px);
    background-size: 75%;
    background-position: center center;
    background-repeat: no-repeat;
}

.big-category {
    position: relative;
    text-align: center;
    display: inline-block;
}

.board-view-container,
.catalog-container,
.bg-container {
    position: relative;
}

.user-bg-container {
    /* border: 1px solid #f7f8fa; */
    border: 1px solid rgba(0,0,0,.125);
    padding: 15px;
    border-radius: 5px;
}

.board-view-podtitle span {
    margin-right: 15px;
    display: inline-block;
    font-size: 14px;
}

.board-view-podtitle span:first-child {
    color: #7A7A7A;
}

.board-view-podtitle span:last-child {
    color: #378EDD;
}

.board-view-price {
    font-weight: 600;
    font-size: 22px;
    position: relative;
    margin-bottom: 15px;
}

.board-view-price-mobile {
    font-weight: bold;
    font-size: 20px;
    margin: 0;
}

.board-view-old-price {
    color: #E83587;
    text-decoration: line-through;
    font-size: 17px;
    margin: 0px;
    font-weight: 600;
}

.breadcrumb {
    background-color: transparent;
    padding-left: 0px;
    padding-bottom: 0px;
    z-index: 5;
    position: relative;
}

.breadcrumb a>span {
    font-size: 13px;
    color: #007bff;
}

.breadcrumb li>span {
    font-size: 13px;
    color: rgba(57, 57, 57, .7);
}

.breadcrumb-item+.breadcrumb-item::before {
    color: #0066f5;
    content: "›";
}

.breadcrumb {
    margin-bottom: 5px !important;
    padding: 0;
}

.width100 {
    width: 100%;
}

.inline-block {
    display: inline-block;
}

.btn-custom-mini {
    display: inline-flex;
    min-height: 40px;
    text-align: center;
    padding: 0 20px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 14px;
    align-items: center;
    justify-content: center;
}

.btn-custom-big {
    text-align: center;
    padding: 10px 25px;
    min-height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 14px;
}

.btn-custom {
    text-align: center;
    padding: 10px 25px;
    min-height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 14px;
}

.btn-custom:hover,
.btn-custom-big:hover,
.btn-custom-mini:hover,
.btn-custom-mini-icon:hover {
    opacity: 0.9;
    text-decoration: none;
}

.btn-custom-mini-icon {
    display: inline-flex;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    outline: none;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
}

.btn-custom-mini-icon i {
    font-size: 15px;
}

.btn-color-green {
    background-color: #53b374 !important;
    color: white !important;
}

.btn-color-light {
    background-color: #6c757d;
    color: #fff !important;
}

.btn-color-blue {
    background-color: #917dfa;
    color: white !important;
}

.btn-color-purple {
    background-color: #965EEB;
    color: white !important;
}

.btn-color-blue-light {
    background: rgb(232, 237, 254);
    color: black;
    box-shadow: rgb(51 51 51 / 2%) 0px 2px 4px 0px;
}

.btn-color-white {
    background-color: white;
    color: black;
}

.btn-color-gradient {
    color: white !important;
    background-image: linear-gradient(to top right, rgb(255, 99, 172), rgb(252, 98, 171), rgb(248, 96, 171), rgb(245, 95, 170), rgb(242, 94, 169), rgb(239, 93, 169), rgb(235, 91, 168), rgb(232, 90, 168), rgb(229, 89, 167), rgb(226, 88, 166), rgb(222, 86, 166), rgb(219, 85, 165));
}

.btn-color-transparent {
    background-color: transparent;
    color: black !important;
}

.btn-color-danger {
    background-color: #E83587;
    color: white !important;
}

span.ion-ios-star-outline {
    color: #c9c9c9;
}

.ion-ios-star {
    color: #ffaf00;
}

.board-view-stars a {
    display: inline-block;
    vertical-align: top;
    margin-top: 4px;
}

.board-view-stars span {
    font-size: 20px;
}

.board-view-user img {
    height: 50px;
}

.board-view-stars {
    vertical-align: top;
}

.board-view-user-avatar img {
    height: 60px;
}

.board-view-user-avatar {
    display: inline-block;
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    text-align: center;
    background-color: #E2E2E2;
}

.board-view-user {
    margin-top: 20px;
}

.mt25 {
    margin-top: 25px;
}

.footer-list-link a {
    display: inline-block;
    text-decoration: underline;
    color: rgb(51, 51, 51);
    margin-right: 15px;
    margin-top: 5px;
}

footer {
    padding: 35px 0px;
    position: relative;
    z-index: 1;
}

.footer-list-text {
    color: rgb(133, 133, 133);
    margin-bottom: 0px;
    font-size: 14px;
}

.mt10 {
    margin-top: 10px !important;
}

.footer-list-link a:hover {
    color: #0069D9;
}

.module-chat-dialog-prev {
    display: none;
    margin-bottom: 10px;
}

.module-chat-dialog-prev span {
    color: black;
    font-size: 14px;
    display: inline-block;
    cursor: pointer;
    font-weight: 500;
}

.module-chat-dialog-prev span:hover {
    color: #007bff;
}

/* xs (<=543px) */
@media (max-width: 575px) {
    .user-balance-summa>div {
        width: 100% !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .footer-list-link {
        margin-bottom: 20px;
    }

    .user-menu-tab {
        margin-top: 20px;
    }

    .user-mobile-menu {
        margin-bottom: 25px;
    }

    .index-sidebar-subscribe {
        margin-top: 25px;
    }

    .ads-services-tariffs {
        height: auto !important;
        margin-bottom: 10px !important;
    }

    .profile-subscriptions-config {
        margin-top: 15px;
    }

    .map-search-controls-draw {
        display: none !important;
    }

    .adaptive-buttons div {
        width: calc(50%);
        float: left;
    }

    .adaptive-buttons button {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .map-search-controls-subscriptions {
        display: inline-block !important;
    }

    .ads-update-category-list {
        left: 0px !important;
        right: 0px !important;
        width: 100% !important;
    }

    .uni-dropdown-content {
        right: 0px !important;
        left: auto !important;
    }

    .catalog-filters-top-item-price,
    .catalog-filters-top-more-filters {
        margin-top: 15px;
    }

    .catalog-filters-top-more-filters-items {
        width: 100% !important;
        margin-left: auto !important;
        left: 0 !important;
    }

    .auth-block-right {
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
    }

    .auth-block {
        max-width: 400px !important;
    }

    .main-search-results {
        left: 0px !important;
        right: 0px !important;
        top: 50px !important;
        width: 100% !important;
        box-shadow: none !important;
        border-radius: 0px !important;
    }

    .list-properties-box-line {
        width: 50% !important;
    }

    .view-list-status-box {
        margin-top: 15px;
    }

    .ad-view-card-user {
        margin-top: 15px;
    }

    .floating-menu-box {
        margin: auto !important;
        display: flex !important;
        padding: 0px 8px !important;
    }

    .floating-menu-box>a {
        margin: 0 8px !important;
        min-width: 50px !important;
    }

    .footer-list-social {
        text-align: left !important;
    }

    .ad-gallery-hover-slider {
        display: none !important;
    }

    .ads-view-photo {
        text-align: center;
    }

    .ads-view-photo iframe {
        height: 300px !important;
        width: 100% !important;
    }

    .ads-view-photo-slider a {
        height: 300px !important;
    }

    .promo-element-add-img {
        margin-top: 20px;
    }

    .article-item-content {
        padding-left: 0px !important;
        padding-top: 15px;
    }

    .shop-item-card-list-content {
        padding-left: 0px !important;
        margin-top: 15px !important;
    }

    #modal-chat-user {
        padding: 0px !important;
        height: 100% !important;
        width: 100% !important;
        overflow: hidden !important;
    }

    #modal-chat-user .module-chat {
        border-radius: 0px !important;
        height: 100% !important;
        overflow: inherit !important;
    }

    #modal-chat-user .modal-chat-user-content {
        height: 100% !important;
        overflow: inherit !important;
    }

    #modal-chat-user .modal-custom-close {
        top: 5px !important;
        right: 5px !important;
    }

    #modal-chat-user .modal-custom {
        margin: 0px !important;
        height: 100% !important;
        border-radius: 0px !important;
    }

    #modal-chat-user .module-chat-users {
        height: 100vh !important;
        padding-top: 30px;
    }

    .chat-dialog-spinner {
        position: fixed !important;
    }

    .adapt-align-right {
        text-align: left !important;
    }

    .profile-button-tariff-box {
        margin-top: 10px;
    }

    .profile-button-tariff-box a,
    .profile-button-tariff-box span {
        width: 100% !important;
        margin: 3px 0;
    }

    .settings-tariff-sidebar-calc {
        position: relative;
    }

    .ads-create-main-data-user-options-phone-buttons {
        margin-left: 0px !important;
        margin-top: 10px !important;
    }

    .button-shop-edit-mobile {
        display: flex !important;
    }

    .ads-view-photo-one {
        width: 100% !important;
        height: auto !important;
    }

    .ads-view-photo-one img {
        height: auto !important;
        width: 100% !important;
    }
}

/* sm (>=576 и <=767) */
@media (min-width: 576px) and (max-width: 767px) {
    .user-balance-payment>div {
        width: 100% !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .user-balance-summa>div {
        width: 100% !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .footer-list-link {
        margin-bottom: 20px;
    }

    .user-menu-tab {
        margin-top: 20px;
    }

    .user-mobile-menu {
        margin-bottom: 25px;
    }

    .index-sidebar-subscribe {
        margin-top: 25px;
    }

    .ads-services-tariffs {
        height: auto !important;
        margin-bottom: 10px !important;
    }

    .profile-subscriptions-config {
        margin-top: 15px;
    }

    .map-search-controls-draw {
        display: none !important;
    }

    .adaptive-buttons div {
        width: calc(50%);
        float: left;
    }

    .adaptive-buttons button {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .map-search-controls-subscriptions {
        display: inline-block !important;
    }

    .ads-update-category-list {
        left: 0px !important;
        right: 0px !important;
        width: 100% !important;
    }

    .uni-dropdown-content {
        right: 0px !important;
        left: auto !important;
    }

    .catalog-filters-top-item-price,
    .catalog-filters-top-more-filters {
        margin-top: 15px;
    }

    .catalog-filters-top-more-filters-items {
        width: 100% !important;
        margin-left: auto !important;
        left: 0 !important;
    }

    .auth-block-right {
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
    }

    .auth-block {
        max-width: 400px !important;
    }

    .main-search-results {
        left: 0px !important;
        right: 0px !important;
        top: 50px !important;
        width: 100% !important;
        box-shadow: none !important;
        border-radius: 0px !important;
    }

    .list-properties-box-line {
        width: 50% !important;
    }

    .view-list-status-box {
        margin-top: 15px;
    }

    .ad-view-card-user {
        margin-top: 15px;
    }

    .floating-menu-box {
        margin: auto !important;
        display: flex !important;
        padding: 0px 8px !important;
    }

    .floating-menu-box>a {
        margin: 0 8px !important;
        min-width: 50px !important;
    }

    .footer-list-social {
        text-align: left !important;
    }

    .ad-gallery-hover-slider {
        display: none !important;
    }

    .ads-view-photo {
        text-align: center;
    }

    .ads-view-photo iframe {
        height: 300px !important;
        width: 100% !important;
    }

    .ads-view-photo-slider a {
        height: 300px !important;
    }

    .promo-element-add-img {
        margin-top: 20px;
    }

    #modal-chat-user {
        padding: 0px !important;
        height: 100% !important;
        width: 100% !important;
        overflow: hidden !important;
    }

    #modal-chat-user .module-chat {
        border-radius: 0px !important;
        height: 100% !important;
        overflow: inherit !important;
    }

    #modal-chat-user .modal-chat-user-content {
        height: 100% !important;
        overflow: inherit !important;
    }

    #modal-chat-user .modal-custom-close {
        top: 5px !important;
        right: 5px !important;
    }

    #modal-chat-user .modal-custom {
        margin: 0px !important;
        height: 100% !important;
        border-radius: 0px !important;
    }

    #modal-chat-user .module-chat-users {
        height: 100vh !important;
        padding-top: 30px;
    }

    .chat-dialog-spinner {
        position: fixed !important;
    }

    .adapt-align-right {
        text-align: left !important;
    }

    .profile-button-tariff-box {
        margin-top: 10px;
    }

    .settings-tariff-sidebar-calc {
        position: relative;
    }

    .ads-create-main-data-user-options-phone-buttons {
        margin-left: 0px !important;
        margin-top: 10px !important;
    }

    .button-shop-edit-mobile {
        display: flex !important;
    }

    .ads-view-photo-one {
        width: 100% !important;
        height: auto !important;
    }

    .ads-view-photo-one img {
        height: auto !important;
        width: 100% !important;
    }
}

/* md (>=768 и <=991) */
@media (min-width: 768px) and (max-width: 991px) {
    .user-balance-payment>div {
        width: 100% !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .user-balance-summa>div {
        width: 100% !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .footer-list-link {
        margin-bottom: 20px;
    }

    .user-menu-tab {
        margin-top: 20px;
    }

    .user-mobile-menu {
        margin-bottom: 25px;
    }

    .index-sidebar-subscribe {
        margin-top: 25px;
    }

    .ads-services-tariffs {
        height: auto !important;
        margin-bottom: 10px !important;
    }

    .profile-subscriptions-config {
        margin-top: 15px;
    }

    .map-search-controls-draw {
        display: none !important;
    }

    .adaptive-buttons div {
        width: calc(50%);
        float: left;
    }

    .adaptive-buttons button {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .map-search-controls-subscriptions {
        display: inline-block !important;
    }

    .ads-update-category-list {
        left: 0px !important;
        right: 0px !important;
        width: 100% !important;
    }

    .uni-dropdown-content {
        right: 0px !important;
        left: auto !important;
    }

    .catalog-filters-top-item-price,
    .catalog-filters-top-more-filters {
        margin-top: 15px;
    }

    .catalog-filters-top-more-filters-items {
        width: 100% !important;
        margin-left: auto !important;
        left: 0 !important;
    }

    .auth-block-right {
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
    }

    .auth-block {
        max-width: 400px !important;
    }

    .main-search-results {
        left: 0px !important;
        right: 0px !important;
        top: 50px !important;
        width: 100% !important;
        box-shadow: none !important;
        border-radius: 0px !important;
    }

    .list-properties-box-line {
        width: 50% !important;
    }

    .view-list-status-box {
        margin-top: 15px;
    }

    .ad-view-card-user {
        margin-top: 15px;
    }

    .floating-menu-box {
        margin: auto !important;
        display: flex !important;
        padding: 0px 8px !important;
    }

    .floating-menu-box>a {
        margin: 0 8px !important;
        min-width: 50px !important;
    }

    .footer-list-social {
        text-align: left !important;
    }

    .ad-gallery-hover-slider {
        display: none !important;
    }

    .ads-view-photo {
        text-align: center;
    }

    .ads-view-photo iframe {
        height: 300px !important;
        width: 100% !important;
    }

    .ads-view-photo-slider a {
        height: 300px !important;
    }

    .promo-element-add-img {
        margin-top: 20px;
    }

    #modal-chat-user {
        padding: 0px !important;
        height: 100% !important;
        width: 100% !important;
        overflow: hidden !important;
    }

    #modal-chat-user .module-chat {
        border-radius: 0px !important;
        height: 100% !important;
        overflow: inherit !important;
    }

    #modal-chat-user .modal-chat-user-content {
        height: 100% !important;
        overflow: inherit !important;
    }

    #modal-chat-user .modal-custom-close {
        top: 5px !important;
        right: 5px !important;
    }

    #modal-chat-user .modal-custom {
        margin: 0px !important;
        height: 100% !important;
        border-radius: 0px !important;
    }

    #modal-chat-user .module-chat-users {
        height: 100vh !important;
        padding-top: 30px;
    }

    .chat-dialog-spinner {
        position: fixed !important;
    }

    .adapt-align-right {
        text-align: left !important;
    }

    .profile-button-tariff-box {
        margin-top: 10px;
    }

    .settings-tariff-sidebar-calc {
        position: relative;
    }

    .ads-create-main-data-user-options-phone-buttons {
        margin-left: 0px !important;
        margin-top: 10px !important;
    }

    .button-shop-edit-mobile {
        display: flex !important;
    }

    .ads-view-photo-one {
        width: 100% !important;
        height: auto !important;
    }

    .ads-view-photo-one img {
        height: auto !important;
        width: 100% !important;
    }
}

/* lg (>=992 и <=1199) */
@media (min-width: 992px) and (max-width: 1199px) {}

/* xl (>=1200) */
@media (min-width: 1200px) {}

.ads-view-photo-bg-gray {
    text-align: center;
    border-radius: 10px;
    background-color: #f7f8fa;
}

.ads-view-photo {
    position: relative;
    visibility: hidden;
    text-align: center;
}

.ads-view-photo-one {
    height: 450px;
    overflow: hidden;
    text-align: center;
}

.ads-view-photo-one img {
    height: 100%;
}

.ads-view-photo iframe {
    height: 450px;
    width: 600px;
}

.ads-view-photo-slider a {
    border-radius: 10px;
    overflow: hidden;
    display: inline-block;
    height: 450px;
    padding: 0px !important;
}

.ads-view-photo-slider a:not(:last-child) {
    margin-right: 3px !important;
}

.ads-view-photo-slider a img {
    height: 100%;
}

.slick {
    text-align: center;
}

.board-view-user-left {
    float: left;
    width: 60px;
    position: relative;
}

.board-view-user-right {
    float: left;
    width: calc(100% - 70px);
    margin-left: 10px;
}

.span-style-height {
    display: block;
    margin-bottom: 7px;
    margin-top: 7px;
}

.span-style {
    display: block;
    margin-bottom: 7px;
    margin-top: 7px;
}

.board-view-sidebar {
    border-radius: 10px;
    padding: 20px;
    background-color: #f7f8fa;
}

.social-icon {
    display: inline-block;
}

.social-icon img {
    height: 32px;
}

.custom-link-2 {
    color: #007bff;
    cursor: pointer;
    transition: all .15s linear;
    font-size: 15px;
}

.custom-link-2:hover {
    text-decoration: underline;
}

.link-button-toggle {
    color: #0069D9;
    cursor: pointer;
    transition: all .15s linear;
}

.link-button-toggle>span {
    font-weight: 100;
    font-size: 15px;
}

.link-button-toggle i {
    font-size: 20px;
    vertical-align: middle;
    margin-right: 5px;
}

.link-button-toggle:hover {
    text-decoration: underline;
}

.social-icon:hover {
    opacity: 0.6 !important;
}

span.badge-pulse-green-small {
    background: #60c400;
    width: 13px;
    height: 13px;
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 50%;
    box-shadow: 0 0 0 rgba(105, 205, 0, .4);
    animation: BadgePulseGreen 1.8s infinite;
    border: 2px solid #fff;
}

@-webkit-keyframes BadgePulseGreen {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(105, 205, 0, .4)
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(105, 205, 0, 0)
    }

    100% {
        -webkit-box-shadow: 0 0 0 20px rgba(105, 205, 0, 0)
    }
}

@keyframes BadgePulseGreen {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(105, 205, 0, .4);
        box-shadow: 0 0 0 0 rgba(105, 205, 0, .4)
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(105, 205, 0, 0);
        box-shadow: 0 0 0 10px rgba(105, 205, 0, 0)
    }

    100% {
        -moz-box-shadow: 0 0 0 20px rgba(105, 205, 0, 0);
        box-shadow: 0 0 0 20px rgba(105, 205, 0, 0)
    }
}

span.badge-pulse-red-small {
    background: #fe195e;
    width: 13px;
    height: 13px;
    position: absolute;
    bottom: 9px;
    right: 0px;
    border-radius: 50%;
    box-shadow: 0 0 0 rgba(254, 25, 94, .4);
    animation: BadgePulseRed 1.8s infinite;
    border: 2px solid #fff;
}

@-webkit-keyframes BadgePulseRed {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(254, 25, 94, .4)
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(254, 25, 94, 0)
    }

    100% {
        -webkit-box-shadow: 0 0 0 20px rgba(254, 25, 94, 0)
    }
}

@keyframes BadgePulseRed {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(254, 25, 94, .4);
        box-shadow: 0 0 0 0 rgba(254, 25, 94, .4)
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(254, 25, 94, 0);
        box-shadow: 0 0 0 10px rgba(254, 25, 94, 0)
    }

    100% {
        -moz-box-shadow: 0 0 0 20px rgba(254, 25, 94, 0);
        box-shadow: 0 0 0 20px rgba(254, 25, 94, 0)
    }
}

.icon-style-mobile {
    font-size: 28px !important;
    font-weight: bold;
}

.valign-center {
    align-items: center;
    display: inline-flex;
    height: 100%;
}

.modal-hidden {
    display: none;
}

.modal-custom-bg {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 15px;
    overflow: auto;
    align-items: center;
    display: flex;
}

.modal-custom {
    margin-left: auto;
    margin-right: auto;
    padding: 25px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 30px rgba(0, 0, 0, .15);
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
}

.width600 {
    max-width: 600px;
}

.width550 {
    max-width: 550px;
}

.modal-custom .board-view-user {
    margin: 0px;
}

.textarea-custom {
    width: 100%;
    border-radius: 8px;
    border: 1px solid rgb(235, 235, 235);
    background-color: white;
    margin-top: 15px;
}

.textarea-custom textarea {
    min-height: 120px;
    border: none;
    outline: none;
    width: 100%;
    padding: 13px;
    resize: none;
}

.textarea-custom-actions {
    padding: 13px;
}

.modal-custom .modal-custom-close {
    position: absolute;
    top: -13px;
    right: -13px;
    cursor: pointer;
    z-index: 3;
    padding: 5px;
    display: inline-flex;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: white;
    box-shadow: rgba(51, 51, 51, 0.13) 0px 4px 12px 0px;
    align-items: center;
    justify-content: center;
    z-index: 15;
}

.modal-custom .modal-custom-close i {
    -webkit-transition: -webkit-transform .4s ease-in-out;
    transition: transform .4s ease-in-out;
    font-size: 16px;
}

.modal-custom .modal-custom-close i:hover {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.catalog-list-options-single {
    padding: 7px 0px;
    font-weight: bold !important;
    display: block;
}

.catalog-list-options-name {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px 0px;
    font-size: 14px;
    font-weight: 500;
}

.catalog-list-options ul {
    list-style: none;
    margin: 0px;
    padding: 0;
}

.catalog-list-options ul li a {
    margin-left: -15px;
    margin-right: -15px;
    padding: 7px 15px;
    color: black;
    font-size: 14px;
    text-decoration: none;
    display: block;
}

.catalog-list-options ul li a:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.catalog-list-options ul li a.active {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.catalog-list-options-name i {
    display: flex;
    margin-left: 5px;
}

.catalog-list-options-content {
    display: none;
    margin-top: 5px;
    margin-bottom: 5px;
}

.catalog-list-options-content .custom-radio {
    margin-bottom: 3px;
}

.catalog-list-options-active .catalog-list-options-content {
    display: block !important;
}

.catalog-list-options-active .catalog-list-options-name i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.filter-input {
    height: 35px;
    position: relative;
    z-index: 5;
}

.filter-input input {
    width: 100%;
    height: 35px;
    border: none;
    outline: none;
}

.filter-input>div {
    position: relative;
    float: left;
    width: calc(50% - 5px);
    padding-left: 35px;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 5px;
    background-color: white;
}

.filter-input>div span {
    position: absolute;
    left: 10px;
    top: 6px;
    display: block;
    color: #9b9b9b;
    font-size: 14px;
}

.filter-input>div:first-child {
    margin-right: 10px;
}

.btn-white {
    color: #212529;
    background-color: white;
    border-color: white;
}

.btn-white:hover {
    color: #212529;
    background-color: #E2E6EA;
    border-color: #E2E6EA;
}

.btn-white.active {
    color: #212529;
    background-color: #E2E6EA;
    border-color: #E2E6EA;
}

.main-search-results {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 60px;
    background-color: white;
    border-radius: 10px;
    z-index: 30;
    padding: 10px 0px;
    display: none;
    overflow: auto;
    max-height: 500px;
    box-shadow: 0 6px 32px #5e5c5c24;
    width: 700px;
}

.main-search-results a {
    display: block;
    width: 100%;
    padding: 7px 20px;
    color: black;
    font-size: 15px;
}

.main-search-results a:hover {
    display: block;
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
    text-decoration: none;
}

.main-search-results img {
    height: 30px;
}

.main-search-results-img {
    float: left;
    width: 30px;
    height: 30px;
    text-align: center;
    margin-right: 10px;
    border-radius: 5px;
    background-color: #E2E2E2;
    overflow: hidden;
    margin-top: 5px;
}

.main-search-results>span {
    display: block;
    width: 100%;
    padding: 7px 20px;
    color: black;
    font-size: 15px;
}

.main-search-results hr {
    margin: 8px 0px;
}

.main-search-results-cont {
    float: left;
    width: calc(100% - 40px);
}

.main-search-results-category {
    display: block;
    font-size: 13px;
    color: #939393;
}

.main-search-results-city {
    display: inline-block;
    font-size: 13px;
    color: #939393;
    margin-left: 10;
}

.main-search {
    width: 100%;
}

.main-search>div {
    position: relative;
}

.main-search input {
    width: 100%;
    height: 55px;
    border: none;
    padding-left: 20px;
    padding-right: 80px;
    transition: all .15s linear;
    border-radius: 5px;
}

.main-search-action {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}

.main-search button {
    padding: 0 13px;
    height: 100%;
    display: inline-flex;
    align-items: center;
}

.main-search input:focus {
    outline: none;
    border-color: #007bff;
}

.main-search button i {
    font-size: 22px;
}

.main-search button:focus,
.main-search button:active {
    outline: none !important;
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.catalog-search {
    float: left;
    width: calc(100% - 60px);
    margin-right: 10px;
}

.width700 {
    max-width: 700px;
}

.no-padding {
    padding: 0px !important;
}

.modal-auth-block-2 {
    padding: 30px;
    height: 100%;
}

.modal-auth-block-1 {
    height: 100%;
    background: url(../../images/auth-bg.png) no-repeat;
}

.modal-auth-block-1 ul {
    list-style: none;
    padding: 20px;
}

.modal-auth-block-1 li {
    margin: 20px 0;
}

.modal-auth-block-1 p {
    margin: 0;
    ont-size: 13px;
    color: white;
}

.modal-auth-block-1 h5 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 5px;
    color: white;
}

.modal-auth-block-1 i {
    font-size: 23px;
    vertical-align: text-top;
}

.modal-auth-block-2 input {
    margin-bottom: 10px;
}

.modal-auth-block-2 button {
    margin-top: 20px;
}

.mt15 {
    margin-top: 15px !important;
}

.modal-auth-block-2 .tab-pane {
    padding: 0px;
}

.modal-auth-block-2 .tab-content {
    margin-top: 25px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: black;
    background-color: #E2E6EA;
}

.nav-pills a {
    color: black;
}

.link-remind {
    color: black;
    font-size: 14px;
}

.link-remind:hover {
    text-decoration: none;
}

#modal-auth-2 .custom-checkbox {
    margin-top: 15px;
}

#modal-auth-2 .custom-control-label {
    font-size: 14px;
}

.modal-auth-1-tab-2 {
    display: none;
}

.modal-action-remind-back i {
    font-size: 20px;
    margin-right: 5px;
}

.modal-action-remind-back {
    display: inline-flex;
    align-items: center;
    margin-bottom: 15px;
}

.dropdown-box-content {
    padding: 15px;
}

.dropdown-box-content-icon {
    text-align: center;
    margin-bottom: 12px;
}

.dropdown-box-content-icon i {
    font-size: 45px;
    color: #30c030;
}

.dropdown-box-content a {
    margin-top: 10px;
    display: block;
}

.content-icon-bg {
    display: inline-flex;
    background-color: rgb(235, 235, 235);
    width: 65px;
    height: 65px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.dropdown-hover:hover .toolbar-dropdown-box {
    display: block;
}

.container {
    position: relative;
    max-width: 1340px;
}

.container .vietcms-content {
    background: #fff;
}

.show-more {
    text-align: center;
    margin-top: 20px;
}

.item-highlight {
    border: 2px solid #b2b2fb;
}

.mb30 {
    margin-bottom: 30px;
}

.mt30 {
    margin-top: 30px;
}

.info-label {
    font-size: 13px;
    display: block;
    margin-top: 10px;
}

.mapAddress {
    height: 300px;
    width: 100%;
    margin-top: 10px;
    z-index: 5;
}

.boxSearchAddress {
    position: relative;
}

.custom-results {
    position: absolute;
    max-height: 300px;
    border-radius: 10px;
    overflow: auto;
    box-shadow: 0 6px 32px #5e5c5c24;
    left: 0px;
    right: 0px;
    display: none;
    top: 50px;
    background-color: white;
    border: 1px solid transparent;
    padding: 15px;
    z-index: 50;
}

.custom-results>div {
    padding: 6px 15px;
    margin-left: -15px;
    margin-right: -15px;
    display: block;
    cursor: pointer;
}

.custom-results>div strong {
    font-size: 14px;
    display: block;
}

.custom-results>div:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.mb10 {
    margin-bottom: 10px;
}

.rub {
    font-family: 'ALS Rubl', sans-serif;
    line-height: normal;
    font-weight: 100;
    font-style: normal;
}

.mt45 {
    margin-top: 45px;
}

.container-custom-search {
    position: relative;
}

.loading-results {
    text-align: center;
    padding: 10px 0;
}

.span-subtitle {
    font-size: 14px;
    color: #a7a7a7;
}

.custom-results p {
    margin: 0px;
    font-size: 15px;
    padding-bottom: 10px;
    border-bottom-color: #F5F5F5;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.ads-image-loader div.spinner-grow {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -15px;
    margin-top: -15px;
}

.ads-image-loader {
    opacity: 0.5;
}

.container-gallery img {
    height: 100%;
}

.ads-image-delete {
    position: absolute;
    right: 5px;
    top: 5px;
}

.ads-image-rotate {
    position: absolute;
    left: 5px;
    top: 5px;
}

.ads-image-item>div {
    overflow: hidden;
    width: 110px;
    height: 100px;
    position: relative;
}

.ads-image-item span {
    padding: 3px 6px;
    border-radius: 5px;
    background-color: white;
    color: black;
}

.box-change-metro-ads span {
    display: block;
    margin: 3px 0px;
    font-size: 15px;
    cursor: pointer;
}

.box-change-metro-ads>span i,
.change-metro-ads i {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    margin-right: 10px;
}

.box-change-metro-ads span:hover {
    text-decoration: underline;
}

.box-change-metro-ads>div {
    display: none;
}

.mt20 {
    margin-top: 20px;
}

.ads-container-metro-station span {
    display: inline-block;
    padding: 5px 0px;
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 5px;
}

.ads-container-metro-station span i,
.SearchMetroResults i {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    margin-right: 5px;
}

.ads-container-area span {
    cursor: pointer;
    margin: 3px 0px;
}

.ads-name-company {
    display: none;
}

.ads-options-change-city,
.options-area,
.options-metro {
    display: none;
}

.ads-form-ajax label span {
    color: red;
}

.ads-blog-articles>div {
    padding: 30px;
    border-radius: 5px;
    background-color: white;
    position: relative;
    margin-bottom: 15px;
    overflow: hidden;
}

.msg-error {
    display: none;
    color: red;
    font-size: 13px;
    margin-top: 5px;
}

.ads-services-tariffs {
    background-color: white;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 8px 16px 0px;
    transition: box-shadow 0.3s ease 0s;
    padding: 25px;
    text-align: center;
    height: 100%;
    position: relative;
    cursor: pointer;
    border: 1px solid #ddd;
}

.ads-services-tariffs-icon {
    text-align: center;
    margin-bottom: 10px;
}

.ads-services-tariffs-icon span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: #F7F8FA;
}

.ads-services-tariffs.active {
    border: 1px solid #007bff;
}

.ads-services-tariffs:hover {
    border: 1px solid #007bff;
}

.ads-services-tariffs-price-now {
    margin-top: 10px;
    font-size: 20px;
    margin-bottom: 0px;
}

.ads-services-tariffs-price-old {
    margin-top: 0px;
    color: rgb(133, 133, 133);
}

.ads-services-tariffs-price-old span {
    font-size: 17px;
    text-decoration: line-through;
}

.ads-services-tariffs h5 {
    margin-top: 15px;
}

.mt40 {
    margin-top: 40px;
}

.ads-services-tariffs-btn-order {
    width: 100%;
    height: 48px;
    padding: 12px;
    background: #28a745;
    border-radius: 50px;
    outline: none;
    border: none;
    cursor: pointer;
    color: white;
    display: none;
}

.ads-services-tariffs-btn-order:hover {
    opacity: 0.8;
}

.ads-services-tariffs-discount {
    position: absolute;
    right: 10px;
    top: 10px;
    display: inline-block;
    padding: 2px 6px;
    font-size: 11px;
    border-radius: 5px;
    color: white;
    background: #F9493A;
    background: -webkit-linear-gradient(top left, #F9493A, #F96E68);
    background: -moz-linear-gradient(top left, #F9493A, #F96E68);
    background: linear-gradient(to bottom right, #F9493A, #F96E68);
}

.animation-modal {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: block;
}

.ads-services-tariffs .input-group {
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.mb5 {
    margin-bottom: 5px;
}

#modal-ad-new h5 {
    margin-top: 10px;
}

#mapAd {
    width: 100%;
    height: 300px;
}

#map_instance {
    width: 100%;
    height: 100%;
}

.ads-view-map {
    margin-top: 25px;
    overflow: hidden;
}

.ads-view-metro div {
    display: inline-flex;
    margin-right: 15px;
}

.ads-view-metro svg {
    margin-right: 5px;
}

.ads-view-list-info {
    margin-bottom: 15px;
}

.ads-view-list-info th {
    color: rgb(133, 133, 133);
    font-weight: normal;
    padding-right: 15px;
    padding: 3px 0px;
}

.ads-view-list-info table {
    width: 100%;
    font-size: 15px;
}

.module-chat-users-img {
    height: 48px;
    width: 48px;
    overflow: hidden;
    border-radius: 8px;
    text-align: center;
    float: left;
    background-color: #DEDEDE;
}

.module-chat-users-img img {
    height: 48px;
}

.module-chat-users>div {
    padding: 15px;
    cursor: pointer;
}

.module-chat-users {
    overflow: auto;
    height: 600px;
}

.module-chat-users-info p {
    margin: 0px;
    font-size: 14px;
    color: rgb(133, 133, 133);
}

.module-chat-users-info {
    position: relative;
    float: left;
    width: calc(100% - 58px);
    margin-left: 10px;
}

.module-chat-users-info-date {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 12px;
    color: rgb(133, 133, 133);
}

.module-chat-users-info-view {
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.module-chat {
    background-color: white;
    border-radius: 5px;
    z-index: 5;
    border: 1px solid transparent;
    height: 600px;
    overflow: hidden;
}

.module-chat-dialog-header {
    min-height: 70px;
    border-bottom: 1px solid #F7F8FA;
    border-left: 1px solid #F7F8FA;
    padding: 10px 15px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background-color: white;
    z-index: 10;
}

.module-chat-dialog-content {
    padding: 20px;
    overflow: auto;
    margin-bottom: 90px;
    margin-top: 70px;
}

.chat-dialog-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -15px;
    margin-left: -15px;
}

.module-chat-users-info-client {
    color: black !important;
    font-size: 14px !important;
}

.module-chat-users>div.active {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.dialog-content-date {
    text-align: center;
    font-size: 14px;
    color: rgb(133, 133, 133);
}

.dialog-content-circle-img {
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #dedede;
}

.dialog-content-circle-img img {
    height: 32px;
}

.dialog-content-msg {
    margin-left: 10px;
    position: relative;
    font-size: 14px;
    font-weight: normal;
    flex-grow: 1;
    padding: 10px;
    border-radius: 10px;
    background-color: #f0f8ff;
}

.dialog-content-item {
    margin: 10px 0;
}

.dialog-content-item-box {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}

.clr {
    clear: left;
    display: block;
}

.module-chat-dialog-header-block-1 {
    height: 48px;
    width: 48px;
    overflow: hidden;
    border-radius: 8px;
    float: left;
    text-align: center;
    background-color: #DEDEDE;
}

.module-chat-dialog-header-block-1 img {
    height: 48px;
}

.module-chat-dialog-header-block-2 {
    float: left;
    width: calc(100% - 58px);
    margin-left: 10px;
    color: black;
    padding-right: 50px;
}

.module-chat-dialog-header-block-2 a {
    color: black;
    font-size: 14px;
}

.module-chat-dialog-header-block-2 p {
    display: block;
    font-size: 14px;
    margin: 0px;
}

.dialog-header-menu {
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -10px;
}

.dialog-header-menu i {
    cursor: pointer;
    font-size: 25px;
}

.ad-status-label {
    display: inline-block;
    padding: 3px 13px;
    border-radius: 25px;
    font-size: 11px;
    margin-top: 5px;
}

.ad-status-label-0 {
    background-color: #ffc107;;
    color: #212529;
}

.ad-status-label-1 {
    background-color: #28a745;
    color: white;
}

.ad-status-label-2 {
    background-color: #6c757d;
    color: #fff;
}

.ad-status-label-3 {
    background-color: #dc3545;
    color: white;
}

.ad-status-label-4 {
    background-color: #ffc107;
    color: #212529;
}

.ad-status-label-5 {
    background-color: #28a745;
    color: white;
}

.ad-status-label-6 {
    background-color: #ffc107;
    color: #212529;
}

.ad-status-label-7 {
    background-color: #6c757d;
    color: #fff;
}

.ad-status-label-8 {
    background-color: #dc3545;
    color: white;
}

.clients-orders-status-0 {
    background-color: #6c757d !important;
    color: white !important;
}

.clients-orders-status-1 {
    background-color: #28a745 !important;
    color: white !important;
}

.clients-orders-status-2 {
    background-color: #dc3545 !important;
    color: white !important;
}

.chat-options-list>div {
    padding: 8px 18px;
    margin-left: -25px;
    margin-right: -25px;
    cursor: pointer;
    font-size: 14px;
}

.chat-options-list>div:hover {
    background-color: #F5F5F5;
}

.chat-options-list>div i {
    font-size: 18px;
}

.chat-options-list {
    position: absolute;
    display: none;
    top: 25px;
    right: 0px;
    background-color: white;
    padding: 0 25px;
    border-radius: 8px;
    width: 210px;
    z-index: 5;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, .07);
}

.module-chat-dialog-footer {
    background-color: #f7f8fa;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 90px;
    z-index: 10;
}

.module-chat-dialog-footer textarea {
    border-radius: 10px;
    width: 100%;
    border: 1px solid white;
    padding: 10px;
    resize: none;
    font-weight: normal;
    background-color: white;
    outline: none;
}

.chat-dialog-text-flex-box,
.chat-dialog-audio-flex-box {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}

.chat-dialog-text-flex-box2 {
    flex-grow: 1;
}

.chat-dialog-text-flex-box1,
.chat-dialog-audio-flex-box1 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-right: 15px;
}

.chat-dialog-text-flex-box3,
.chat-dialog-audio-flex-box4 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    padding-right: 10px;
}

.chat-dialog-audio-flex-box3 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
}

.dialog-content-msg-date {
    text-align: right;
    margin-top: 5px;
}

.dialog-content-msg-date span {
    font-size: 12px;
    color: rgb(133, 133, 133);
}

.dialog-content-msg-text {
    margin: 0px;
    word-break: break-word;
}

.chat-dialog-empty {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.chat-dialog-empty p {
    font-size: 14px;
    margin-top: 16px;
    color: rgb(133, 133, 133);
}

.chat-dialog-empty i {
    font-size: 55px;
    color: rgb(133, 133, 133);
}

.chat-dialog-content-action {
    background-color: rgb(250, 250, 250);
    border-radius: 12px;
    padding: 15px;
    max-width: 400px;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    text-align: center;
    margin-top: 15px;
}

.chat-dialog-content-action img {
    height: 48px;
}

.auth-block {
    margin: 0 auto;
    max-width: 700px;
    border: 1px solid #f7f8fa;
}

.auth-block-left {
    padding: 35px;
    height: 100%;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.auth-block-right {
    padding: 35px;
    background-color: #f7f8fa;
    height: 100%;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}

.auth-logo {
    text-align: center;
    margin-top: 35px;
    margin-bottom: 35px;
}

.auth-block-right-box .custom-checkbox {
    margin: 30px 0;
}

.ul-list-icon {
    float: left;
    width: 40px;
}

.ul-list-title {
    float: left;
    width: calc(100% - 40px);
}

.ul-list-title p {
    margin: 0px;
}

.ul-list-icon i {
    font-size: 25px;
}

.ul-list-box {
    margin-bottom: 10px;
}

.button-style-custom {
    width: 100%;
    height: 45px;
    border-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
}

.button-style-custom:hover {
    opacity: 0.8;
    box-shadow: rgba(51, 51, 51, 0.024) 0px 2px 4px 0px;
    text-decoration: none;
}

.color-green {
    background-color: #53b374 !important;
    color: white !important;
}

.color-light {
    background: rgb(235, 235, 235);
    color: black !important;
}

.color-white {
    background: white;
    color: black;
}

.color-blue {
    background: rgb(127, 100, 237);
    color: white !important;
}

.input-style-custom {
    height: 48px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.input-style2-custom {
    height: 40px !important;
    margin-bottom: 10px;
    border: 1px solid #ced4da !important;
    border-radius: 10px;
}

.auth-list {
    text-align: center;
}

.auth-list>a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    margin: 5px;
    padding: 5px;
    border-radius: 50px;
    text-align: center;
    height: 35px;
    width: 35px;
}

.auth-list>a img {
    height: 25px;
}

.auth-google img {
    height: 24px !important;
}

.auth-list>a:hover {
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, .07);
    color: white;
    text-decoration: none;
}

.auth-login,
.auth-phone {
    background-color: rgb(235, 235, 235);
    color: black;
}

.auth-block-right-box-tab-2 {
    display: none;
}

.auth-recover-link {
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
}

.auth-agreement {
    font-size: 13px;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 15px;
}

.auth-block-left-box ul {
    list-style: none;
    padding: 0px;
}

.auth-block-left-box li {
    color: white;
    padding: 0px;
}

.auth-block-left-box h5 {
    font-size: 20px;
    margin-bottom: 0px;
}

.auth-left-box-title {
    margin-bottom: 15px;
}

.auth-left-box-desc {
    margin-top: 0px;
}

.ml10 {
    margin-left: 10px;
}

.mr10 {
    margin-right: 10px;
}

.mr12 {
    margin-right: 12px;
}

.mb40 {
    margin-bottom: 40px;
}

.auth-block-right-box-tab-1-2,
.auth-block-right-box-tab-1-3 {
    display: none;
}

.auth-block-right-box-tab-1-2-back {
    font-size: 15px;
    margin-top: 0px;
    margin-bottom: 10px;
}

.auth-block-right-box-tab-1-2-back span {
    cursor: pointer;
}

.button-style-custom .button-ajax-loader {
    margin-right: 5px;
    display: none;
}

.user-avatar-img {
    border-radius: 50%;
    overflow: hidden;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    vertical-align: middle;
    position: relative;
    justify-content: center;
    position: relative;
}

.user-avatar-img img {
    height: 100px;
}

.user-avatar {
    text-align: center;
    padding: 10px;
}

.user-avatar-replace {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0px;
    right: 0px;
    bottom: 0px;
    align-items: center;
    justify-content: center;
    display: none;
    backdrop-filter: blur(5px);
}

.user-avatar-replace i {
    font-size: 25px;
    color: white;
}

.user-avatar-img:hover .user-avatar-replace {
    display: inline-flex;
}

.user-avatar h4 {
    margin-top: 10px;
    font-size: 20px;
}

.user-avatar p {
    font-size: 14px;
    margin-bottom: 5px;
}

.user-menu a {
    display: block;
    padding: 10px 10px;
    color: rgb(51, 51, 51);
    font-weight: normal;
    position: relative;
}

.user-menu a:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
    text-decoration: none;
}

.user-menu {
    margin-top: 20px;
}

.user-menu i {
    margin-left: 7px;
}

.small-title {
    color: rgb(133, 133, 133);
    font-size: 14px;
    font-weight: normal;
}

.mt0 {
    margin-top: 0px;
}

#user-form-avatar {
    display: none;
}

.user-menu .active {
    color: #7B9AFE;
    text-decoration: none;
}

.dropdown-box-list-link a {
    display: block;
    padding: 8px 20px;
    color: black;
    font-size: 14px;
    font-weight: normal;
}

.dropdown-box-list-link a:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
    text-decoration: none;
}

.dropdown-box-list-link svg,
.user-menu svg,
.mobile-fixed-menu-user-link svg {
    margin-right: 8px;
}

.label-count {
    border: 2px solid white;
    font-size: 10px;
    background-color: #F75059;
    display: none;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: white !important;
    margin-left: 5px;
}

.mini-avatar-img {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    overflow: hidden;
    border-radius: 50%;
    height: 44px;
}

.mini-avatar-img img {
    height: 44px;
}

.mini-avatar .label-count {
    position: absolute;
    top: -6px;
    right: 1px;
}

.mini-avatar {
    position: relative;
    height: 44px;
    display: block;
}

.module-chat-users-info-msg {
    font-size: 13px !important;
}

.module-chat-users-count-msg {
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.chat-dialog-content-block {
    text-align: center;
    padding-top: 10px;
}

.chat-dialog-content-block p {
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 14px;
}

.chat-dialog-content-block span {
    cursor: pointer;
    font-size: 14px;
    margin-top: 0px;
}

.modal-view-phone-display a {
    font-size: 27px;
    color: black;
    font-weight: 600;
    text-decoration: none;
}

.board-view-user-date {
    font-size: 12px;
    font-weight: normal;
    color: rgb(133, 133, 133);
    display: block;
}

.modal-view-phone-display {
    text-align: center;
}

.modal-notification-content h4,
.modal-complaint-notification h4 {
    margin: 0px;
    font-size: 17px;
    font-weight: 600;
    color: rgb(51, 51, 51);
}

.modal-notification-content,
.modal-complaint-notification {
    padding: 20px 0;
    text-align: center;
}

.modal-notification-content,
.modal-confirm-content {
    text-align: center;
}

.modal-notification-content i,
.modal-complaint-notification i {
    font-size: 60px;
    color: green;
    margin-bottom: 10px;
}

.modal-confirm-content h4 {
    margin: 0px;
    font-size: 23px;
    font-weight: 600;
    color: rgb(51, 51, 51);
    line-height: 1.3;
}

.modal-confirm-content p {
    margin-top: 15px;
    margin-bottom: 0px;
}

.modal-confirm-content {
    margin-bottom: 25px;
}

.modal-custom-button>div {
    float: left;
    width: calc(50% - 5px);
}

.modal-custom-button>div:nth-child(1) {
    margin-right: 5px;
}

.modal-custom-button>div:nth-child(2) {
    margin-left: 5px;
}

.tab-content {
    background-color: white;
    padding: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border-color: transparent !important;
}

.nav-tabs {
    border: none !important;
}

.nav-tabs .nav-link:hover {
    border: 1px solid transparent !important;
}

.modal-geo-list input {
    width: 100%;
    height: 40px;
    margin-top: 20px;
}

.cities-list {
    margin-top: 30px;
}

.cities-list a {
    display: block;
    color: black;
    font-size: 15px;
    color: black;
    cursor: pointer;
    padding: 6px 12px;
    border-radius: 5px;
}

.cities-list a:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
    text-decoration: none;
}

.main-category-list-item {
    text-align: center;
    word-break: break-word;
    position: relative;
}

.main-category-list-item>a {
    text-decoration: none;
}

.main-category-list-item>a:hover .main-category-list-name {
    text-decoration: underline;
}

.main-category-list {

    text-align: center;
}

.main-category-list-icon {
    text-align: center;
    display: inline-block;
}

.main-category-list-icon img {
    height: 130px;
}

.main-category-list-name {
    display: block;
    margin-top: 13px;
    color: black;
    font-size: 15px;
    font-weight: 400;
    padding: 0 5px;
}

.main-category-list-icon-circle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-left: -50px;
    background-color: #E5F2F9;
    transition: all 0.5s ease;
    z-index: -1;
}

.main-category-list-item:hover .main-category-list-icon-circle {
    width: 120px;
    height: 120px;
    margin-left: -60px;
}

.owl-theme .owl-nav [class*=owl-] {
    margin-top: 50px !important;
}

.cities-letter {
    font-size: 55px;
    color: rgba(57, 57, 57, .4);
}

.uni-dropdown {
    position: relative;
}

.uni-dropdown-content {
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: 0 0 30px rgba(0, 0, 0, .15);
    background: rgb(255, 255, 255);
    padding: 10px 0px;
    border-radius: 8px;
    width: 200px;
    display: none;
    z-index: 20;
}

.uni-dropdown-content span {
    display: block;
    padding: 3px 15px;
    cursor: pointer;
}

.uni-dropdown-content a {
    display: block;
    padding: 3px 15px;
    cursor: pointer;
    color: black;
}

.uni-dropdown-align {
    display: inline-flex;
    align-items: center;
    vertical-align: sub;
}

.uni-dropdown-content span:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.uni-dropdown-content a:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.uni-dropdown-name {
    cursor: pointer;
    font-size: 15px;
    height: 42px;
    padding-right: 10px;
    padding-top: 10px;
}

.uni-select {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #ced4da;
    height: 42px;
    position: relative;
    cursor: pointer;
    background-color: #f7f8fa;
}

.uni-select-list {
    max-height: 250px;
    overflow: auto;
    z-index: 5;
    left: -1px;
    right: -1px;
    top: 40px;
    display: none;
    padding: 5px 0px;
    background-color: #f7f8fa;
    border: 1px solid #ced4da;
    position: absolute;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.uni-select-name {
    position: relative;
    text-align: left;
    width: 100%;
    font-size: 15px;
    padding-right: 30px;
    padding-left: 10px;
    height: 100%;
    display: flex;
    align-items: center;
}

.uni-select-name i {
    position: absolute;
    right: 10px;
    top: 12px;
}

.uni-select-list input {
    display: none;
}

.uni-select-list label {
    margin: 0px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 15px;
    position: relative;
    display: block;
}

.uni-select-list label span {
    color: #393939 !important;
    font-weight: normal;
    padding-right: 17px;
    display: block;
    font-size: 14px;
}

.uni-select-list label:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.uni-select-list i {
    position: absolute;
    right: 10px;
    top: 8px;
    color: green;
    display: none;
}

.uni-select-open {
    border-radius: 0px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    z-index: 10;
}

.uni-select-open .uni-select-list {
    display: block;
}

.uni-select-item-active i {
    display: block;
}

.uni-select-name span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    color: #393939;
    font-weight: normal;
    font-size: 14px;
}

.ads-status-info {
    margin-bottom: 15px;
}

.ads-status-block {
    text-align: center;
    min-height: 380px;
}

.status-block-icon div {
    width: 94px;
    height: 94px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: black;
    border-radius: 50%;
    background: #F1F1F1;
    margin-bottom: 10px;
}

.status-block-icon div i {
    font-size: 40px;
}

.mt60 {
    margin-top: 60px;
}

.board-view-container .ad-status-label {
    font-size: 13px !important;
}

.mt100 {
    margin-top: 100px;
}

.ion-ios-heart {
    color: #cecece;
}

.modal-auth-content {
    position: relative;
    text-align: center;
}

.board-view-service {
    margin-bottom: 10px;
    margin-top: 15px;
}

.board-view-service h5 {
    font-size: 17px;
}

.circle-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    background: transparent;
    border: 1px solid #53b374;
    margin-bottom: 15px;
}

.circle-icon i {
    font-size: 40px;
    color: #53b374;
}

.display-load-page {
    display: none;
}

.preload {
    text-align: center;
}

.preload-spinner {
    width: 2.8rem;
    height: 2.8rem;
    color: #17a2b8 !important;
}

.variable-photo-gallery {
    position: relative;
    overflow: hidden;
    visibility: hidden;
}

.variable-photo-gallery img {
    height: 68px;
}

.variable-photo-gallery div {
    border-radius: 5px;
    overflow: hidden;
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
}

.variable-photo-gallery div:focus {
    outline: none;
}

.variable-photo-gallery img:hover {
    opacity: 0.8;
    cursor: pointer;
}

.form-filter-submit button {
    width: 100%;
    margin-top: 5px;
}

.form-filter-submit {
    margin-top: 10px;
    z-index: 10;
}

.shop-category-list,
.blog-category-list {
    position: relative;
    background-color: #f7f8fa;
    border-radius: 10px;
    padding: 15px;
}

.sidebar-filter {
    position: relative;
    background-color: #f7f8fa;
    border-radius: 10px;
    padding: 15px;
}

.form-filter {
    position: relative;
}

.catalog-no-results {
    text-align: center;
}

.catalog-no-results-box {
    display: inline-block;
    padding: 45px 0;
}

.catalog-no-results-box svg {
    margin-bottom: 25px;
    color: #8F949B;
    background: rgba(120, 82, 249, 1.0);
    background: -webkit-linear-gradient(top left, rgba(120, 82, 249, 1.0), rgba(173, 99, 231, 1.0));
    background: -moz-linear-gradient(top left, rgba(120, 82, 249, 1.0), rgba(173, 99, 231, 1.0));
    background: linear-gradient(to bottom right, rgba(120, 82, 249, 1.0), rgba(173, 99, 231, 1.0));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.catalog-no-results-box h5 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
}

.catalog-no-results-box img {
    height: 150px;
}

.icon-title-question {
    font-size: 14px;
    cursor: pointer;
    color: white;
    padding: 5px 15px;
    background-color: #53b374;
    display: inline-block;
    border-radius: 25px;
    margin-top: 10px;
}

.button-modal-extend-shop {
    font-size: 14px;
    cursor: pointer;
    color: #007bff;
    display: inline-block;
    margin-left: 10px;
    font-weight: 600;
    text-decoration: underline;
}

.button-modal-extend-shop:hover {
    text-decoration: none;
}

.modal-top-views-content-icon {
    background: url(../../images/startup-img.png) center;
    width: 100%;
    height: 150px;
    border-bottom-right-radius: 60%;
    border-bottom-left-radius: 90%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
}

.modal-top-views-content-title {
    padding: 25px;
}

.modal-top-views-content-title h4 {
    font-weight: bold;
}

.modal-top-views-content {
    text-align: center;
}

.label-sale-mini {
    font-size: 11px;
    border-radius: 5px;
    padding: 0px 4px;
    color: white;
    margin-left: 5px;
    display: inline-block;
    background: #F9493A;
    background: -webkit-linear-gradient(top left, #F9493A, #F96E68);
    background: -moz-linear-gradient(top left, #F9493A, #F96E68);
    background: linear-gradient(to bottom right, #F9493A, #F96E68);
}

.modal-top-views-content .modal-custom-button {
    padding: 0px 25px;
}

.user-menu-tab div {
    display: inline-block;
    margin-right: 20px;
    padding-bottom: 10px;
    cursor: pointer;
    font-size: 15px;
    color: rgb(133, 133, 133);
}

.user-menu-tab {
    border-bottom: 2px solid #F0F0F0;
}

.user-menu-tab div.active {
    color: black;
    font-weight: 500;
}

.user-menu-tab div:hover {
    color: black;
}

.user-menu-tab-content {
    margin-top: 25px;
}

.item-grid-label-status {
    position: absolute;
    top: 4px;
    right: 6px;
    z-index: 10;
}

.item-grid-label-status span {
    font-size: 10px !important;
}

.user-menu-tab-content {
    display: none;
}

.user-menu-tab-content.active {
    display: block;
}

.user-block-no-result {
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    text-align: center;
    padding: 30px 0;
}

.user-block-no-result p {
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 500;
}

.user-block-no-result img {
    height: 110px;
}

.user-balance-payment>div {
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 80px;
    background-color: #f7f8fa;
    margin-right: 10px;
    margin-top: 13px;
    border: 1px solid white;
    cursor: pointer;
}

.user-balance-payment>div:hover {
    border: 1px solid blue;
}

.user-balance-payment input {
    display: none;
}

.user-balance-payment>div.active {
    border: 1px solid blue;
}

.user-balance-payment>div img {
    max-height: 40px;
    max-width: 80px;
}

.label-count-number {
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
    justify-content: center;
    font-size: 14px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    color: black;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, .08);
}

.module-balance h5 {
    display: inline-flex;
    align-items: center;
}

.user-balance-summa>div {
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    background-color: #f7f8fa;
    margin-right: 15px;
    margin-top: 13px;
    border: 1px solid white;
    cursor: pointer;
    position: relative;
    float: left;
    width: calc(25% - 14px);
}

.user-balance-summa>div:hover {
    border: 1px solid blue;
}

.user-balance-summa>div.active {
    border: 1px solid blue;
}

.user-balance-summa>div input {
    display: none;
}

.user-balance-summa>div p {
    font-size: 23px;
    font-weight: bold;
    margin: 0;
}

.user-balance-summa>div div span {
    font-size: 16px;
    color: #53b374;
}

.user-balance-summa>div div {
    text-align: center;
}

.mt35 {
    margin-top: 35px;
}

.user-balance-summa>div>span {
    position: absolute;
    display: inline-block;
    border-radius: 5px;
    background-color: #5b0;
    color: white;
    padding: 1px 4px;
    font-size: 12px;
    left: 0px;
    top: -10px;
}

.balance-input-amount {
    display: none;
    border: 1px solid #f7f8fa;
    text-align: center;
    padding: 15px 0;
    border-radius: 5px;
}

.balance-input-amount div {
    width: 180px;
    margin: 0 auto;
}

.balance-input-amount h6 {
    margin-bottom: 10px;
}

.redirect-form-pay {
    display: none;
}

.user-mobile-menu {
    position: relative;
    border-radius: 5px;
    background-color: white;
    margin-top: 15px;
}

.user-mobile-menu>span {
    padding: 10px 15px;
    display: block;
    font-size: 15px;
    cursor: pointer;
    position: relative;
}

.user-mobile-menu>span i {
    position: absolute;
    right: 15px;
    top: 15px;
}

.user-mobile-menu>div a {
    display: block;
    padding: 10px 15px;
    color: rgb(51, 51, 51);
    font-weight: normal;
    position: relative;
}

.user-mobile-menu>div a:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
    text-decoration: none;
}

.user-mobile-menu>div i {
    font-size: 23px;
    display: inline-block;
    margin-right: 5px;
    vertical-align: bottom;
}

.user-mobile-menu>div {
    padding: 5px 0;
    display: none;
    border-top: 2px solid #F7F8FA;
}

.user-data-item {
    padding: 7px 0;
}

.user-data-item label {
    margin-bottom: 5px;
}

.user-info {
    padding: 10px;
    border-radius: 5px;
    background-color: #F7F8FA;
    display: block;
    margin-top: 15px;
}

.v-middle {
    display: inline-flex;
    align-items: center;
}

.j-right {
    justify-content: flex-end;
}

.user-list-change {
    text-decoration: underline;
    color: #7B9AFE;
    cursor: pointer;
}

.user-list-change:hover {
    text-decoration: none;
}

.user-name-company {
    display: none;
}

.confirm-edit-email {
    display: none;
    margin-bottom: 0px;
}

#modal-edit-phone input[name=code] {
    display: none;
}

.user-edit-phone-save {
    display: none;
}

.modal-notification-email-content-icon {
    background: url(../../images/gift-img.png) center;
    width: 100%;
    height: 150px;
    border-bottom-right-radius: 60%;
    border-bottom-left-radius: 90%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
}

.modal-notification-email-content-title {
    padding: 35px;
}

.modal-notification-email-content-title h4 {
    font-weight: bold;
}

.modal-notification-email-content {
    text-align: center;
}

.modal-notification-email-content .modal-custom-button {
    padding: 0px 25px;
}

.catalog-sort>div {
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
    position: relative;
}

.catalog-sort>div>i {
    font-size: 25px;
    cursor: pointer;
    color: #777777;
}

.catalog-title {
    margin: 0px;
    font-size: 30px;
}

.catalog-sort>div.active i {
    color: #7B9AFE;
}

.catalog-sort-link-button {
    font-size: 15px;
    cursor: pointer;
    color: #777777;
    transition: all .10s linear;
}

.catalog-sort-link-button:hover {
    color: #7B9AFE;
}

.catalog-sort-link-button i {
    font-size: 25px;
}

.catalog-sort .uni-dropdown-name {
    padding-top: 7px;
}

.item-list-order {
    overflow: hidden;
    background-color: #f7f8fa;
    border-radius: 10px;
    margin-bottom: 15px;
}

.item-list-img img {
    height: 100%;
}

.item-list-img a {
    text-decoration: none;
}

.item-list-img {
    overflow: hidden;
    height: 170px;
    display: block;
    position: relative;
    text-align: center;
}

.item-list {
    margin: 10px;
    overflow: hidden;
    background-color: #f7f8fa;
    border-radius: 10px;
}

.item-list-title {
    font-size: 18px;
    color: black;
    display: block;
    line-height: normal;
    margin-bottom: 5px;
    font-weight: 600;
}

.item-list-link-order {
    font-size: 15px;
    color: #7B9AFE;
    display: block;
    margin-top: 15px;
}

.item-list-price-now {
    font-size: 17px;
    color: black;
    font-weight: bold;
    display: block;
}

.item-list-price-old {
    font-size: 15px;
    color: #ef4c4c;
    font-weight: bold;
    display: block;
    text-decoration: line-through;
}

.item-list-content {
    padding: 15px;
}

.item-list-content-text {
    margin-top: 10px;
    margin-bottom: 0px;
}

.catalog-sort .uni-dropdown-content {
    text-align: left;
}

.catalog-sort .uni-dropdown-content {
    right: 0;
    left: auto;
    width: 160px;
}

.item-list-city-name {
    display: inline-block;
    margin-right: 10px;
}

.item-list-date {
    display: inline-block;
    margin-right: 10px;
}

.item-list-rate {
    display: inline-block;
}

.gutters10 {
    margin-left: -10px;
    margin-right: -10px;
}

.child4>div:nth-child(n+4) {
    display: none;
}

.owl-carousel .owl-item img {
    width: auto !important;
}

.catalog-category-slider {
    position: relative;
}

.catalog-subcategory {
    visibility: hidden;
    padding: 0 50px;
}

.catalog-subcategory a {
    background-color: #f7f8fa;
    border-radius: 10px;
    display: flex;
    padding: 0px 15px;
    height: 65px;
    align-items: center;
    flex-direction: row;
}

.catalog-subcategory-count-ad {
    display: inline-block;
    font-size: 13px;
}

.catalog-subcategory-image {
    height: 100%;
    align-items: center;
    display: inline-flex;
}

.catalog-subcategory-image img {
    width: 45px;
    margin-right: 15px;
}

.catalog-subcategory-name {
    height: 100%;
    align-items: center;
    display: inline-flex;
}

.catalog-subcategory div.slick-slide {
    width: 220px;
}

.list-properties {
    display: flex;
    flex-wrap: wrap;
}

.list-properties-display {
    width: 100%;
}

.list-properties-span1 {
    width: 50%;
    color: #5a6c7d;
    align-items: flex-start;
    background: #fff;
    display: inline;
    padding-right: 3px;
    position: relative;
}

.list-properties-span2 {
    width: 50%;
    box-sizing: border-box;
    display: block;
    margin-bottom: 8px;
    word-break: break-word;
}

.list-properties-item {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.list-properties-box-line {
    box-sizing: border-box;
    display: block;
    margin-bottom: 8px;
    word-break: break-word;
    width: 35%;
    position: relative;
}

.list-properties-box-line:before {
    border-bottom: 1px dotted rgba(0, 26, 52, .16);
    bottom: 0.2em;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    width: 100%;
}

.complain-toggle {
    color: black;
    cursor: pointer;
    transition: all .15s linear;
    margin-top: 20px;
}

.complain-toggle>span {
    font-size: 14px;
    color: #ff6a59;
}

.complain-toggle>span:hover {
    text-decoration: underline;
}

.modal-edit-notifications-content>span {
    font-size: 13px;
    margin-top: 25px;
    color: rgba(133, 133, 133, 0.64);
    display: block;
}

.place-banner {
    width: 100%;
    max-height: 260px;
    z-index: 1;
    display: block;
    text-align: center;
    position: relative;
    margin-bottom: 15px;
}

.place-banner img {
    max-height: 260px;
    object-fit: contain;
}

.place-banner-control-admin {
    cursor: pointer;
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.place-banner-content {
    margin: 0 auto;
    display: inline-block;
}

.place-banner-content img {
    max-width: 100%;
}

.stretching-banner {
    width: 100%;
}

.stretching-banner img {
    width: 100%;
}

.plug-banner {
    border: 1px solid #D7DEED;
    border-radius: 5px;
    width: 100%;
    padding: 25px;
    text-align: center;
}

.plug-banner h5 {
    margin: 0px;
    font-size: 18px;
}

.plug-banner p {
    margin-bottom: 0px;
    margin-top: 5px;
}

.mobile-filter {
    position: fixed;
    top: 0;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: white;
    z-index: 100;
    overflow: auto;
    display: none;
}

.mobile-filter-header {
    text-align: center;
    padding: 20px;
    position: relative;
}

.mobile-filter-header h4 {
    margin: 0px;
}

.mobile-filter-header i {
    position: absolute;
    top: 20px;
    left: 12px;
    font-size: 26px;
    cursor: pointer;
    color: rgb(143, 143, 143);
}

.page-link {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin-left: -1px;
    color: black;
    background-color: transparent;
    border: none;
    border-radius: 50px;
    margin: 0 2px;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-radius: 50px !important;
}

.page-item:last-child .page-link {
    border-radius: 50px !important;
}

.view-list-status>span {
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    background-color: #53b374;
    padding: 5px 15px;
    color: white;
    font-size: 12px;
}

.auction-strong {
    font-size: 15px;
    font-weight: bold;
}

.auction-title {
    margin-bottom: 0px;
    margin-top: 0px;
}

.box-auction {}

.box-auction .board-view-price {
    margin-top: 0px;
    margin-bottom: 10px;
}

.modal-auction-content input {
    height: 45px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.auction-user-item {
    text-align: center;
    margin: 5px;
}

.auction-user-item-avatar img {
    height: 60px;
}

.auction-user-item-avatar {
    display: inline-flex;
    overflow: hidden;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #DEDEDE;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.item-list-duration {
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 13px;
}

.item-list-duration span {
    font-weight: bold;
}

.pulse-time-grid {
    animation: pulse_time 1.5s ease-in-out infinite;
    display: inline-block;
}

.pulse-time {
    color: red;
    animation: pulse_time 1.5s ease-in-out infinite;
    display: inline-block;
}

@keyframes pulse_time {
    0% {
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    50% {
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -webkit-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
    }

    100% {
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

.var_price_1,
.var_price_2 {
    display: none;
}

.create-tab-status span {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #EBEBEB;
    margin-right: 15px;
}

.create-tab-status .active span {
    background: rgba(120, 82, 249, 1.0);
    background: -webkit-linear-gradient(top left, rgba(120, 82, 249, 1.0), rgba(173, 99, 231, 1.0));
    background: -moz-linear-gradient(top left, rgba(120, 82, 249, 1.0), rgba(173, 99, 231, 1.0));
    background: linear-gradient(to bottom right, rgba(120, 82, 249, 1.0), rgba(173, 99, 231, 1.0));
}

.create-tab-status {
    position: relative;
}

.create-tab-status>div {
    margin-bottom: 15px;
    cursor: pointer;
}

.create-tab-status>div>div {
    display: inline-block;
}

.create-tab-status>div::after {
    content: "";
    position: absolute;
    top: 6px;
    left: 5px;
    height: 95%;
    width: 1px;
    z-index: -1;
    background-color: rgb(235, 235, 235);
}

.create-tab-2,
.create-tab-3,
.create-tab-4,
.create-tab-5,
.create-tab-6 {
    display: none;
}

.create-list-categories a {
    display: block;
    margin: 7px 0;
    color: black;
}

.create-list-categories a.active {
    color: black !important;
    font-weight: bold;
}

.create-list-categories h6 {
    margin-bottom: 13px;
    margin-top: 0px;
    font-weight: bold;
    font-size: 20px;
}

.create-list-categories-more {
    font-weight: bold;
    color: black !important;
}

.create-list-categories-back {
    margin-top: 0px;
    cursor: pointer;
    color: black !important;
    margin-bottom: 10px;
    font-weight: bold;
}

.create-list-tabs textarea {
    min-height: 150px;
    border-radius: 8px;
}

.create-info i {
    font-size: 22px;
    vertical-align: top;
}

.create-info {
    padding: 18px;
    border-radius: 10px;
    background-color: #e5f5ff;
    margin-top: 0px;
    margin-bottom: 0px;
    display: block;
    color: black;
}

.buy-sidebar {
    border-radius: 5px;
    padding: 25px;
    border: 1px solid #ebebeb;
}

.buy-sidebar h4 {
    margin-top: 0px;
}

.buy-secure-label i {
    font-size: 20px;
    color: green;
}

.buy-secure-label {
    font-weight: bold;
}

.info-text {
    padding: 15px;
    border-radius: 8px;
    background-color: #e9e6fa;
}

.buy-item-product-img img {
    height: 100%;
}

.buy-item-product-img {
    width: 70px;
    height: 70px;
    float: left;
    overflow: hidden;
    border-radius: 5px;
    text-align: center;
    background-color: #DEDEDE;
}

.buy-item-product-title {
    float: left;
    width: calc(100% - 80px);
    margin-left: 10px;
}

.buy-item-product-title strong {
    font-size: 19px;
}

.buy-sidebar-commission {
    margin-top: 5px;
    display: block;
    color: green;
}

.item-secure {
    color: white;
    background: #CBF56F;
    background: -webkit-linear-gradient(top left, #CBF56F, #309A3E);
    background: -moz-linear-gradient(top left, #CBF56F, #309A3E);
    background: linear-gradient(to bottom right, #CBF56F, #309A3E);
}

.ad-view-secure-label i {
    font-size: 20px;
    color: green;
}

.ad-view-secure-label {
    margin-top: 15px;
    background: linear-gradient(to top right, rgb(101, 115, 255), rgb(122, 113, 238), rgb(143, 112, 220), rgb(164, 110, 203), rgb(185, 109, 185), rgb(206, 107, 168), rgb(178, 125, 175), rgb(149, 143, 181), rgb(121, 162, 188), rgb(92, 180, 194), rgb(64, 198, 201), rgb(35, 216, 207));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.checkbox {
    display: inline-block;
    padding: 5px 5px 5px 46px;
    margin: 0;
    position: relative;
    cursor: pointer;
}

.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: inherit;
}

.checkbox span {
    display: inline-block;
    color: black !important;
    padding: 1px 0;
}

.checkbox span:before,
.checkbox span:after {
    content: '';
    position: absolute;
    top: 50%;
    transition: .3s;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.checkbox span:before {
    left: 0;
    height: 14px;
    margin-top: -7px;
    width: 36px;
    border-radius: 7px;
    background: #aaa;
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, .2);
}

.checkbox span:after {
    left: 0;
    margin-top: -10px;
    height: 20px;
    width: 20px;
    background: #fafafa;
    border-radius: 50%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .5);
}

.checkbox input:checked+span:before {
    background-color: #93c9f3;
}

.checkbox input:checked+span:after {
    background-color: #2793e6;
    left: 16px;
}

.checkbox input:focus+span:before {
    box-shadow: 0 0 0 3px rgba(50, 150, 255, .2);
}

.checkbox input:disabled+span {
    opacity: .35;
}

.checkbox input:disabled+span:before {
    background: #ccc;
}

.item-list-auction-rate {
    text-align: right;
}

.item-list-auction-rate a {
    display: inline-block;
    padding: 7px 10px;
    border-radius: 25px;
    background-color: #6c757d;
    color: #fff !important;
}

.item-list-order-status {
    display: inline-block;
    padding: 3px 8px;
    background-color: #e9f0fe;
    color: black;
    border-radius: 25px;
    font-size: 13px;
}

.item-list-order-number {
    display: block;
    font-weight: bold;
}

.item-list-order-date {
    display: block;
    margin-top: 10px;
}

.board-view-ads-left {
    float: left;
    width: 50px;
    position: relative;
}

.board-view-ads-right {
    float: left;
    width: calc(100% - 60px);
    margin-left: 10px;
}

.board-view-ads-img img {
    height: 50px;
}

.board-view-ads-img {
    display: inline-block;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 5px;
    text-align: center;
    background-color: #E2E2E2;
}

.order-status {
    font-weight: bold;
    font-size: 15px;
}

.order-date {
    display: block;
    margin-top: 10px;
}

.minheight300 {
    min-height: 300px;
}

.icon-circle-status {
    justify-content: center;
    text-align: center;
}

.icon-circle-status div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 0 0 30px rgba(0, 0, 0, .15);
}

.icon-circle-status div i {
    font-size: 45px;
    color: white;
}

.status-red {
    background-color: #e8275a;
    color: white;
}

.status-green {
    background-color: #1ed97c;
    color: white;
}

.star-rating {
    font-size: 25px;
}

.star-rating span {
    cursor: pointer;
}

.ion-ios-star {
    color: #ffaf00;
}

span.ion-ios-star-outline {
    color: #c9c9c9;
}

.user-review-item {
    padding: 15px;
    background-color: #f7f8fa;
    border-radius: 10px;
    margin-bottom: 10px;
}

.user-review-item-avatar1 {
    text-align: center;
    border-radius: 50%;
    background-color: #dedede;
    width: 50px;
    height: 50px;
    float: left;
    overflow: hidden;
}

.user-review-item-avatar1 img {
    height: 100%;
}

.user-review-item-avatar2 {
    float: left;
    width: calc(100% - 60px);
    margin-left: 10px;
}

.user-review-item-avatar2 span {
    font-size: 12px;
    margin-left: 10px;
    display: inline-block;
    color: #7f9799;
}

.user-review-item-title {
    display: block;
    color: black;
    margin-top: 3px;
    margin-bottom: 0px;
    color: #7f9799;
}

.user-review-item .star-rating {
    font-size: 20px;
    height: 20px;
}

.user-review-item .star-rating span {
    cursor: default;
}

.user-review-item-delete {
    display: inline-block;
    padding: 3px 6px;
    cursor: pointer;
    border: 1px solid red;
    color: red;
    border-radius: 50px;
    font-size: 13px;
}

.user-review-item-delete:hover {
    background-color: red;
    color: white;
}

.dispute-secure-attach {
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 10px;
    display: block;
}

.file-dispute-attach {
    display: none;
}

.order-cancel-deal {
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
}

.page-menu-list {
    background-color: #f7f8fa;
    border-radius: 5px;
    padding: 15px 0;
    margin-bottom: 15px;
}

.page-menu-list a {
    display: block;
    padding: 5px 15px;
    color: black;
}

.page-menu-list a:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.page-menu-list a.active {
    background-color: #E5F2F9;
}

.page-feedback {
    padding: 7px 15px;
    display: block;
}

.page-feedback-icon {
    float: left;
    width: 40px;
    height: 40px;
}

.page-feedback-name {
    float: left;
    width: calc(100% - 50px);
    margin-left: 10px;
}

.page-feedback-icon span {
    display: inline-flex;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #005FF9;
    color: white;
    align-items: center;
    justify-content: center;
}

.page-feedback-icon i {
    font-size: 25px;
}

.page-feedback-name strong {
    display: block;
    margin-bottom: 0px;
}

.page-feedback:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.feedback-answers-questions {
    background-color: #F7F8FA;
    border-radius: 5px;
    padding: 15px 20px;
    cursor: pointer;
    margin-bottom: 15px;
}

.feedback-answers-questions>p {
    margin: 0px;
    font-size: 15px;
    font-weight: bold;
    position: relative;
    padding-right: 20px;
}

.feedback-answers-questions>div {
    display: none;
    margin-top: 10px;
}

.feedback-answers-questions i {
    display: inline-block;
    font-size: 17px;
    position: absolute;
    top: 2px;
    right: 0px;
}

.feedback-success {
    padding: 15px;
    border-radius: 5px;
    background-color: #dff8e7;
    display: none;
}

.feedback-answers-questions:hover {
    background-color: #f1f4fa;
}

.catalog-button-city-option {
    text-align: center;
    height: 55px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    position: relative;
}

.catalog-action-options {
    border-radius: 5px;
    background: white;
}

.modal-geo-options-tab div {
    display: inline-block;
    margin-right: 18px;
    cursor: pointer;
}

.modal-geo-options-tab-content {
    margin-top: 25px;
}

.modal-geo-options-tab div {
    display: inline-block;
    padding: 5px 20px;
    border-radius: 25px;
}

.modal-geo-options-tab div.active {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
    color: black;
}

.modal-geo-options-tab-content>div {
    display: none;
}

.modal-geo-options-tab-content>div.active {
    display: block;
}

.city-option-count {
    background-color: #F75059;
    font-size: 10px;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    margin-left: 5px;
    border: 3px solid white;
    vertical-align: text-bottom;
}

.catalog-button-city-option span {
    vertical-align: center;
}

.button-accept-geo-options,
.button-clear-geo-options {
    width: 100%;
}

.geo-options-areas,
.geo-options-metro {
    max-height: 300px;
    overflow: auto;
}

.metro-station-color {
    display: inline-block;
    width: 15px;
    height: 3px;
    vertical-align: middle;
}

.index-sidebar-subscribe {
    border-radius: 5px;
    width: 100%;
    background: rgba(120, 82, 249, 1.0);
    background: -webkit-linear-gradient(top left, rgba(120, 82, 249, 1.0), rgba(173, 99, 231, 1.0));
    background: -moz-linear-gradient(top left, rgba(120, 82, 249, 1.0), rgba(173, 99, 231, 1.0));
    background: linear-gradient(to bottom right, rgba(120, 82, 249, 1.0), rgba(173, 99, 231, 1.0));
    text-align: center;
    padding: 20px;
}

.index-sidebar-subscribe h5,
.index-sidebar-subscribe p {
    color: white;
}

.index-sidebar-subscribe button {
    width: 100%;
    margin-top: 15px;
}

.index-sidebar-subscribe img {
    height: 85px;
    margin-bottom: 10px;
}

.width250 {
    width: 250px;
}

.button-inline {
    display: inline-block;
}

.user-subscribe-success {
    display: none;
}

.secure-page-content {
    text-align: center;
    max-width: 900px;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    position: relative;
}

.service-page-content {
    text-align: center;
    max-width: 900px;
    color: white;
    padding: 15px;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    position: relative;
}

.service-page-container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.pg-service-element1 {
    position: fixed;
    right: 40px;
    top: 160px;
    background: url(../../images/pg-turbo-f3hdk354.png);
    width: 190px;
    height: 161px;
}

.pg-service-element2 {
    position: fixed;
    left: 90px;
    top: 50px;
    background: url(../../images/pg-turbo-fhdd4354.png);
    width: 200px;
    height: 176px;
}

.pg-service-element3 {
    position: fixed;
    left: 170px;
    bottom: -40px;
    background: url(../../images/pg-turbo-k1dcc04354.png);
    width: 150px;
    height: 203px;
}

.pg-service-element4 {
    position: fixed;
    right: 140px;
    bottom: -50px;
    background: url(../../images/pg-turbo-511gh4b354.png);
    width: 190px;
    height: 183px;
}

.service-page-content>h2 {
    font-weight: 800;
    font-size: 45px;
    font-family: arial;
}

.service-page-content>p {
    font-weight: 400;
    font-size: 20px;
}

.service-page-content a,
.secure-page-content a {
    width: 310px;
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    border-radius: 32px;
    background: #fe304b;
    display: block;
    color: #fff;
    margin: 30px auto;
    transition: all .3s ease;
}

.service-page-block-left img,
.service-page-block-right img {
    height: 74px;
    margin-bottom: 15px;
}

.service-page-block-left {
    text-align: left;
    margin-top: 20px;
    position: relative;
}

.service-page-block-left>div {
    max-width: 450px;
    display: inline-block;
    text-align: center !important;
}

.service-page-block-right {
    text-align: right;
    margin-top: 20px;
    position: relative;
}

.service-page-block-right>div {
    max-width: 450px;
    display: inline-block;
    text-align: center !important;
}

.zigelem1 {
    position: absolute;
    top: 25px;
    right: 220px;
    background: url("../images/pg-turbo-444as08.png") no-repeat;
    width: 113px;
    height: 135px;
    display: inline-block;
}

.zigelem2 {
    position: absolute;
    top: 25px;
    left: 220px;
    background: url("../images/pg-turbo-1000as08.png") no-repeat;
    width: 113px;
    height: 135px;
    display: inline-block;
}

.zigelem3 {
    position: absolute;
    top: 25px;
    right: 220px;
    background: url("../images/pg-turbo-444as08.png") no-repeat;
    width: 113px;
    height: 135px;
    display: inline-block;
}

.module-comments-avatar {
    float: left;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    background-color: #DEDEDE;
    text-align: center;
}

.module-comments-content {
    float: left;
    width: calc(100% - 60px);
    margin-left: 10px;
    background-color: #F7F8FA;
    padding: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
}

.module-comments-avatar img {
    height: 100%;
}

.module-comments-content p {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 15px;
}

.module-comments-content p span {
    font-size: 13px;
    color: #939cb0;
    display: inline-block;
}

.module-comments-otvet {
    display: inline-block;
    font-size: 15px;
    cursor: pointer;
    color: #939cb0;
    margin-top: 5px;
}

.module-comments-otvet:hover {
    color: black;
}

.module-comments>div {
    margin-bottom: 15px;
}

.module-comments-form-otvet textarea {
    border: 2px solid #D6DCE7;
    border-radius: 15px;
    padding: 15px;
    min-height: 70px;
    padding-right: 70px;
    width: 100%;
}

.module-comments-content .module-comments-form-otvet {
    display: none;
}

.module-comments-form-otvet {
    margin-top: 10px;
    position: relative;
}

.module-comments-form-otvet textarea:focus,
.module-comments-form-otvet textarea:active {
    outline: none;
}

.module-comments-form-send {
    position: absolute;
    right: 18px;
    top: 18px;
    cursor: pointer;
    background-color: #D6DCE7;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #838995;
    border: none;
}

.module-comments-form-send i {
    font-size: 20px;
}

.module-comments-delete {
    position: absolute;
    display: inline-block;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 13px;
    color: red;
}

.auth-captcha {
    display: none;
    margin-top: 15px;
}

.auth-captcha img {
    overflow: hidden;
    border-radius: 5px;
}

.blog-header h1 {
    font-weight: bold;
    font-size: 30px;
}

.article-item {
    margin-bottom: 15px;
    border-bottom: 1px solid #f7f8fa;
    padding-bottom: 15px;
}

.article-item-content {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-left: 15px;
}

.article-item-image {
    height: 220px;
    overflow: hidden;
    text-align: center;
    border-radius: 10px;
    position: relative;
}

.article-item-content p {
    margin-top: 5px;
    margin-bottom: 0px;
}

.article-item-content span {
    font-size: 14px;
    color: #aeaeae;
}

.article-item-content-title {
    display: block;
    color: black;
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 10px;
    font-weight: 600;
}

.article-item-content-link {
    margin-top: 20px;
}

.mb50 {
    margin-bottom: 50px;
}

.article-item-cat-name {
    display: inline-block;
    margin-bottom: 5px;
    color: black;
    font-size: 14px;
}

.blog-view-header h1 {
    font-weight: bold;
    font-size: 30px;
}

.blog-view-header span {
    font-size: 15px;
    display: inline-block;
    margin: 0 5px;
}

.min-height250 {
    min-height: 250px;
}

.min-height500 {
    min-height: 500px;
}

.blog-view-article-rand a {
    display: block;
    margin-bottom: 15px;
}

.blog-view-article-rand p {
    color: black;
    font-size: 15px;
    margin: 0px;
    font-weight: bold;
    z-index: 5;
    position: relative;
}

.blog-view-article-rand a:hover {
    opacity: 0.8;
    text-decoration: none;
}

.view-article-rand-image {
    max-height: 250px;
    background-size: cover;
    position: relative;
    margin-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
}

.view-article-rand-image img {
    width: 100%;
}

.bg-container-shadow {
    padding: 20px;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .07);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .07);
    background-color: white;
    border-radius: 5px;
}

.item-create h5 {
    margin-top: 0px;
    margin-bottom: 13px;
    font-weight: bold;
    font-size: 15px;
}

.item-create {
    margin-bottom: 15px;
}

.create-tab-prev {
    display: inline-block;
    cursor: pointer;
    margin-right: 10px;
    vertical-align: middle;
}

.create-tab-prev i {
    font-size: 30px;
    color: black;
}

.max-width-filter-item input,
.max-width-filter-item .uni-select {
    max-width: 250px;
}

.min-height-600 {
    min-height: 600px;
}

.board-view-price-currency {
    position: absolute;
    left: 0px;
    top: 42px;
    right: 0px;
    background-color: white;
    border-radius: 5px;
    padding: 15px;
    text-align: right;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, .08);
    z-index: 10;
    display: none;
}

.board-view-price-currency>span {
    display: block;
    padding: 5px;
    font-size: 17px;
    font-weight: bold;
}

.price-currency i {
    font-size: 17px;
    background-color: white;
    border-radius: 10px;
    padding: 3px 8px;
    cursor: pointer;
}

.word-break {
    word-break: break-word;
}

.catalog-list-options-content .custom-checkbox {
    margin-bottom: 5px;
}

.catalog-list-options-search input {
    height: 33px !important;
    font-size: 13px !important;
    background-color: white !important;
}

.catalog-list-options-search {
    margin-bottom: 10px;
}

.catalog-modal-list-options-items>div {
    display: inline-block;
    margin-right: 15px;
}

.catalog-modal-list-options {
    margin-bottom: 22px;
}

.filters-get-count {
    background-color: #F75059;
    color: white;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    align-items: center;
    display: inline-block;
}

.preload-scroll {
    display: none;
}

.catalog-results {
    position: relative;
}

.minheight200 {
    min-height: 200px;
}

.info-not-phone {
    border: 1px solid #EFEFEF;
    border-radius: 5px;
    padding: 11px 15px;
    text-align: center;
}

.mobile-footer-menu {
    position: fixed;
    left: 15px;
    right: 15px;
    bottom: 15px;
    z-index: 100;
    text-align: center;
}

.mobile-footer-menu-item {
    display: inline-block;
    width: 100%;
    padding: 13px 15px;
    text-align: center;
    cursor: pointer;
    position: relative;
    border-radius: 50px;
}

.mobile-footer-menu-item i {
    font-size: 20px;
    vertical-align: bottom;
}

.mobile-footer-menu-item:hover {
    text-decoration: none;
    opacity: 0.9;
}

.catalog-list-category a {
    color: black;
    margin: 3px 0;
    display: block;
}

.catalog-list-category a.active {
    font-weight: bold;
}

.dropdown-lang-list img {
    width: 20px;
    margin-right: 5px;
}

.ml5ib {
    display: inline-block;
    margin-left: 5px;
}

.minheight500 {
    min-height: 500px;
}

.minheight400 {
    min-height: 400px;
}

.strong-line {
    height: 3px;
    color: #ececec;
    background-color: #ececec;
}

.icon-green-big {
    font-size: 40px;
    color: green;
}

.secure-page-header {
    padding: 50px;
    text-align: center;
    border-bottom: 4px solid #f7f8fa;
}

.lang-js {
    display: none;
}

.main-category-list-initials {
    font-size: 27px;
    color: black;
    text-transform: uppercase;
    text-decoration: none;
}

.block-cookies {
    box-shadow: 0 8px 32px -8px rgba(0, 0, 0, .4);
    background-color: white;
    border-radius: 10px;
    padding: 25px;
    position: fixed;
    left: 15px;
    bottom: 15px;
    margin-right: 15px;
    z-index: 150;
    max-width: 450px;
    display: none;
}

.block-cookies p {
    margin: 0px;
}

.block-cookies span {
    margin-top: 15px;
}

.footer-list-social {
    margin-top: 8px;
    text-align: right;
}

.item-label-seo-filter {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px;
}

.item-label-seo-filter a {
    display: inline-block;
    cursor: pointer;
    color: #007bff;
    margin-top: 5px;
    margin-right: 10px;
    font-weight: 500;
}

.list-seo-filters {
    margin-bottom: 10px;
    margin-top: 10px;
}

.catalog-more-filter {
    position: relative;
}

.catalog-more-filter-show,
.catalog-more-filter-hide {
    width: 100%;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    padding: 13px 15px;
    border-radius: 5px;
    font-size: 14px;
}

.catalog-more-filter-hide {
    display: none;
}

.catalog-more-filter-action .catalog-list-options {
    display: none;
}

.catalog-more-filter-action .catalog-list-options:nth-child(-n+4) {
    display: block;
}

.item-country-hover .active {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

img[data-inv='1'] {
    filter: brightness(0) invert(1) !important;
}

.item-service-top {
    color: #fff;
    background: #46E0A0;
    background: -webkit-linear-gradient(top left, #46E0A0, #269E88);
    background: -moz-linear-gradient(top left, #46E0A0, #269E88);
    background: linear-gradient(to bottom right, #46E0A0, #269E88);
}

.item-service-turbo {
    color: #fff;
    background: #FF5F95;
    background: -webkit-linear-gradient(top left, #FF5F95, #DB356D);
    background: -moz-linear-gradient(top left, #FF5F95, #DB356D);
    background: linear-gradient(to bottom right, #FF5F95, #DB356D);
}

.variable-photo-prev {
    position: absolute;
    left: 0px;
    top: 50%;
    background-color: white;
    padding: 7px 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
    z-index: 5;
    margin-top: -10px;
}

.variable-photo-next {
    position: absolute;
    right: 0px;
    top: 50%;
    background-color: white;
    padding: 7px 5px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    cursor: pointer;
    z-index: 5;
    margin-top: -10px;
}

.variable-photo-prev i,
.variable-photo-next i {
    font-size: 15px;
}

.variable-video-gallery {
    height: 68px !important;
    width: 68px !important;
    background-color: #F7F8FA !important;
    border-radius: 5px !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
}

.variable-video-gallery span {
    height: 32px;
    width: 32px;
    display: inline-block;
    background: url(../../images/youtube.png) no-repeat;
}

.custom-control-label {
    font-weight: normal;
}

.prev-modal-change-user-review {
    cursor: pointer;
    margin-bottom: 10px;
}

.mobileAuthCardLogout {
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
}

.map-search-controls {
    position: absolute;
    top: 0;
    left: -60px;
}

.map-search-controls>div>span {
    background-color: white;
    cursor: pointer;
    box-shadow: 0 2px 3px 1px rgba(0, 0, 0, .2);
    display: inline-flex;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    position: relative;
}

.map-search-controls>div {
    margin-bottom: 10px;
}

.map-search-controls i {
    font-size: 20px;
}

.map-search-container {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.map-search-controls-draw-close {
    display: none;
}

.map-search-offers-header {
    height: 60px;
    margin-bottom: 10px;
    display: none;
    padding: 15px;
}

.map-search-offer-header {
    padding: 15px;
}

.map-search-offer-back-catalog {
    color: #007bff;
    cursor: pointer;
    display: block;
    font-size: 14px;
}

.map-search-offer-back-catalog i {
    font-size: 17px;
}

.map-search-offer-back-catalog:hover {
    color: #0d62be;
}

.map-search-offers-list .preload {
    margin-top: 50%;
}

.map-search-offers-list {
    overflow: auto;
    padding: 5px;
}

.map-no-result {
    text-align: center;
    margin-top: 30px;
    padding: 15px;
}

.map-no-result i {
    font-size: 55px;
    background: linear-gradient(to top right, rgb(101, 115, 255), rgb(122, 113, 238), rgb(143, 112, 220), rgb(164, 110, 203), rgb(185, 109, 185), rgb(206, 107, 168), rgb(178, 125, 175), rgb(149, 143, 181), rgb(121, 162, 188), rgb(92, 180, 194), rgb(64, 198, 201), rgb(35, 216, 207));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.map-search-offers-subscribe span {
    display: inline-block;
    cursor: pointer;
    color: #007bff;
}

.map-search-offers-subscribe span i {
    font-size: 15px;
}

.map-search-offers-subscribe span:hover {
    text-decoration: underline;
}

.map-search-offers-header-count-info {
    font-size: 18px;
    cursor: pointer;
    color: black;
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
}

.mr8 {
    margin-right: 8px;
}

.mr5 {
    margin-right: 5px;
}

.modal-ads-subscribe-form input {
    height: 43px;
}

.modal-ads-subscribe-form select {
    height: 43px;
    width: 100%;
    font-size: 14px;
}

.modal-ads-subscriptions-block-2 {
    display: none;
}

.profile-subscriptions-name {
    color: black;
    font-size: 16px;
    font-weight: bold;
}

.profile-subscriptions-config {
    text-align: right;
}

.profile-subscriptions-config .btn-group button:first-child {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.profile-subscriptions-config .btn-group button:last-child {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.profile-subscriptions-list:not(:last-child) {
    border-bottom: 3px solid #F7F8FA;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.checkSuccess {
    font-size: 60px;
    display: inline-block;
    color: green;
    margin-bottom: 10px;
}

.modal-map-footer {
    text-align: right;
    margin-top: 35px;
}

.modal-map-footer div {
    display: inline-flex;
    font-size: 0px;
}

.modal-map-footer button {
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
}

.modal-map-footer div:nth-child(even)>button {
    margin-left: 10px;
}

.modal-map-footer div:nth-child(odd)>button {
    margin: 0px;
}

.map-search-controls-filters {
    position: relative;
}

.map-search-offer-content-button i {
    font-size: 20px;
}

.map-search-offer-content-button {
    text-align: center;
    height: 39px;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: 14px;
    background-color: #6c757d;
    color: black !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.image-autofocus {
    position: relative;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.blog-view-text-content img {
    max-width: 100% !important;
    height: auto !important;
}

.sliders-wide {
    width: 100%;
    position: relative;
}

.load-sliders-wide-show {
    display: block;
    width: 100%;
}

.sliders-wide-title {
    position: absolute;
    top: 25px;
    color: rgba(51, 51, 51, 0.8);
    right: 25px;
    left: 25px;
}

.sliders-wide-title1 {
    font-size: 20px;
    font-weight: 600;
    display: block;
    margin-bottom: 0px;
}

.sliders-wide-title2 {
    font-size: 15px;
    display: block;
    word-break: break-word;
    margin-top: 3px;
}

.sliders-wide-item {
    position: relative;
}

.sliders-wide-item:focus,
.sliders-wide-item a:focus {
    outline: none;
    border: none;
}

.sliders-wide-prev,
.sliders-wide-next {
    background: #fff;
    width: 35px;
    height: 35px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, .06) 0 4px 8px 0;
    position: absolute;
    top: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
    opacity: .95;
    margin-top: -17px;
}

.sliders-wide-prev i,
.sliders-wide-next i {
    font-size: 20px;
}

.sliders-wide-prev:hover,
.sliders-wide-next:hover {
    opacity: 1;
}

.sliders-wide-item:hover {
    opacity: 0.9;
}

.promo-add-element {
    text-align: center;
    margin: 30px 0;
}

.promo-add-element span {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #002F34;
    color: white;
    border-radius: 50%;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
}

.promo-add-element span i {
    font-size: 20px;
}

.promo-add-element span:hover {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -o-transform: scale(0.9);
}

.modal-promo-elements-list>div {
    margin: 8px 0;
    background-color: #F7F8FA;
    border-radius: 10px;
    padding: 15px 20px;
}

.modal-promo-elements-list>div:hover {
    background-color: #f1f3f8;
}

.modal-promo-elements-list>div span {
    font-weight: bold;
}

.modal-promo-elements-list img {
    width: 100%;
}

.modal-promo-elements-list>div:hover {
    cursor: pointer;
    opacity: 0.9;
}

.promo-menu-control-fixed-button {
    width: 300px;
    margin-bottom: 20px;
}

.promo-menu-control-fixed-button>div {
    width: calc(50% - 3px);
    float: left;
}

.promo-menu-control-fixed {
    position: fixed;
    top: 20px;
    right: 30px;
    z-index: 15;
}

.promo-menu-control {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, .08);
    width: 300px;
    position: relative;
}

.promo-menu-control>div>label {
    font-weight: bold;
    display: block;
}

.promo-menu-control>div {
    margin-bottom: 10px;
}

.promo-logo {
    margin-top: 20px;
    margin-bottom: 20px;
}

.promo-logo span {
    cursor: pointer;
    display: inline-block;
    padding: 7px 25px;
    font-weight: bold;
    border: 2px dashed black;
    border-radius: 10px;
}

.promo-element-add-img span {
    padding: 15px 40px;
    border: 2px dashed black;
    border-radius: 10px;
    display: inline-block;
    cursor: pointer;
}

.promo-logo span i {
    font-size: 20px;
}

.promo-element-add-img span i {
    font-size: 30px;
}

.promo-logo img {
    cursor: pointer;
}

.promo-controls {
    position: absolute;
    top: 0px;
    right: 0px;
    display: inline-block;
    z-index: 15;
    background-color: white;
    padding: 7px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.promo-controls i {
    font-size: 20px;
}

.promo-controls span {
    cursor: pointer;
}

.promo-controls-delete {
    color: red;
}

.promo-element {
    position: relative;
}

.promo-element-add-img img {
    max-width: 100%;
}

.promo-element-add-img {
    text-align: center;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10 !important;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.promo-menu-control-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.promo-menu-control-close i {
    font-size: 20px;
}

.promo-menu-control input {
    font-size: 14px;
}

.promo-menu-control-element,
.promo-menu-control-image,
.promo-menu-control-button,
.promo-menu-control-icon,
.promo-menu-control-body {
    display: none;
}

.promo-area {
    margin-bottom: 50px;
}

.promo-element-button span {
    cursor: pointer;
    display: inline-block;
    padding: 7px 25px;
    font-weight: bold;
    border: 2px dashed black;
    border-radius: 10px;
}

.promo-icon-list {
    padding: 0;
    list-style: none;
}

.promo-icon-list li {
    float: left;
    margin-right: 10px;
    margin-top: 10px;
}

.promo-icon-list li i {
    font-size: 22px;
    cursor: pointer;
}

.promo-icon-list li i:hover {
    color: #7092fe;
}

.ballon-point {
    max-width: 400px;
}

.ymaps-2-1-78-b-cluster-content__header,
.ymaps-2-1-78-balloon-content__header {
    display: none !important;
}

.dz-dropzone-delete i,
.dz-dropzone-sortable i {
    font-size: 16px;
    color: black;
    cursor: pointer !important;
}

.dz-dropzone-delete {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer !important;
    padding: 3px 6px;
    border-radius: 5px;
    background-color: white;
    color: black;
    z-index: 20 !important;
}

.dz-dropzone-sortable {
    position: absolute;
    top: 5px;
    left: 5px;
    cursor: pointer !important;
    padding: 3px 6px;
    border-radius: 5px;
    background-color: white;
    color: black;
    z-index: 20 !important;
}

.dropzone {
    border: 2px dashed #d7d7d7 !important;
    border-radius: 10px !important;
    padding: 7px !important;
    min-height: 0px !important;
    position: relative;
    z-index: 1;
}

.dropzone:hover {
    border-color: rgb(215 215 215) !important;
    color: black !important;
}

.dz-message i {
    font-size: 37px;
    color: #B8B8B8;
}

.dz-message {
    color: #B8B8B8;
}

.dropzone .dz-preview {
    margin: 7px !important;
}

.dropzone .dz-preview .dz-image {
    z-index: 0 !important;
}

.dropzone .dz-preview.dz-image-preview {
    background: transparent !important;
}

.dz-preview-custom:hover img,
.dz-preview:hover img {
    left: 0px !important;
    top: 0px !important;
}

.user-block-promo-content {
    padding: 30px;
}

.user-block-promo {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
    overflow: hidden;
    position: relative;
}

.user-block-promo p {
    font-size: 16px;
    margin: 0px;
}

.display-inline {
    display: inline-flex;
}

.user-list-promo {
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
}

.modal-open-shop-content {
    text-align: center;
}

.modal-open-shop-content-icon {
    background: url(../../images/shop_3345733.png) center;
    width: 100%;
    height: 150px;
    border-bottom-right-radius: 60%;
    border-bottom-left-radius: 90%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-color: #E1F2FF;
}

.user-shop-price-old {
    text-decoration: line-through;
    color: red;
}

.user-shop-price {
    font-size: 16px;
}

.overflow-hidden {
    overflow: hidden;
}

.mobile-icon-menu-close,
.icon-menu-close {
    display: none;
}

.user-warning-seller-safety {
    background-color: #FFE6E6;
    border-radius: 10px;
    overflow: hidden;
    height: 200px;
    margin-bottom: 25px;
    position: relative;
    display: none;
}

.seller-safety-text {
    padding: 35px;
}

.seller-safety-img {
    height: 315px;
    margin-top: -92px;
    background-image: url(../../images/ankk977392Ajj.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
}

.warning-seller-safety-close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    cursor: pointer;
    z-index: 5;
}

.warning-seller-safety-close i {
    font-size: 18px;
}

.footer-list-cities a {
    display: block;
    margin-top: 5px;
}

.footer-list-cities {
    padding: 35px 0;
    background-color: #F7F8FA;
}

.footer-list-cities a {
    color: rgb(133, 133, 133);
}

.link_active {
    color: #007bff !important;
}

.footer-bg {
    background-color: white;
    border-top: 1px solid #F7F8FA;
}

.icon-link-middle {
    font-size: 18px;
    vertical-align: text-bottom;
}

.shop-notsliders {
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
    color: black;
}

.shop-sliders {
    height: 450px;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
}

.shop-sliders-items {
    height: 450px;
}

.shop-sliders .slick-slide {
    margin: 0 !important;
}

.shop-sliders .shop-sliders-footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    background-color: transparent;
}

.shop-block-logo-img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    float: left;
    margin-right: 15px;
}

.shop-block-logo-img img {
    height: 100%;
}

.shop-block-logo-name {
    float: left;
    width: calc(100% - 115px);
    height: 100px;
    display: inline-flex;
    color: white;
    align-items: center;
}

.shop-notsliders .shop-block-logo-name {
    color: black;
}

.shop-block-logo {
    padding: 25px;
}

.shop-block-logo-name h5 {
    margin-top: 0px;
    font-weight: bold;
    font-size: 23px;
}

.shop-block-control {
    padding: 25px;
    display: inline-flex;
    align-items: flex-end;
    height: 100%;
}

.shop-block-control-button {
    padding: 10px 20px;
    background-color: white;
    border-radius: 8px;
    cursor: pointer;
    display: inline-block;
}

.shop-notsliders .shop-block-control-button {
    background-color: #917dfa;
    color: white;
}

.shop-mobile-counters {
    text-align: center;
}

.shop-block-control-button:hover {
    opacity: 0.9;
    text-decoration: none;
}

.shop-block-control-indicators span,
.shop-block-control-indicators a {
    display: inline-block;
    margin-right: 30px;
    color: white;
}

.shop-notsliders .shop-block-control-indicators span,
.shop-notsliders .shop-block-control-indicators a {
    color: black;
}

.shop-block-control-indicators {
    text-align: center;
}

.shop-container-sliders {
    margin-top: 25px;
    margin-bottom: 25px;
}

.shop-container-sliders-add {
    text-align: center;
    border-radius: 10px;
    border: 2px dashed #D7DEED;
    padding: 25px 15px;
    cursor: pointer;
    color: #838383;
    transition: all .1s linear;
}

.shop-container-sliders-add:hover {
    border-color: #838383;
    color: black;
}

.shop-container-sliders-img span {
    cursor: pointer;
    display: inline-block;
    padding: 5px 8px;
    background-color: white;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 0px;
    right: 0px;
}

.shop-container-sliders-img span i {
    font-size: 13px;
}

.shop-container-sliders-img {
    height: 100px;
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    border-radius: 10px;
}

.shop-slide-mobile .shop-block-logo-img {
    height: 70px;
    width: 70px;
}

.shop-slide-mobile .shop-block-logo-name {
    float: left;
    width: calc(100% - 85px);
    height: 70px;
    display: inline-flex;
    align-items: center;
}

.shop-slide-mobile h5 {
    font-size: 19px;
}

.hover-link-shop-subscribers:hover {
    text-decoration: underline;
    cursor: pointer;
}

.shop-subscribers-list-item {
    border-top: 1px solid #f0f0f0;
    padding: 10px 35px;
}

.shop-subscribers-list-item-img {
    height: 40px;
    width: 40px;
    float: left;
    margin-right: 10px;
    text-align: center;
    overflow: hidden;
    border-radius: 50%;
}

.shop-subscribers-list-item-img img {
    height: 100%;
}

.shop-subscribers-list-item-name {
    float: left;
    width: calc(100% - 50px);
    height: 40px;
    display: inline-flex;
    align-items: center;
}

.shop-subscribers-list-item-name a {
    font-size: 14px;
}

.action-load-span-start {
    display: none;
    vertical-align: text-bottom;
}

.title-and-link a {
    display: inline-flex;
    font-size: 14px;
    height: 100%;
    vertical-align: middle;
}

.title-and-link strong {
    margin-right: 15px;
}

.title-and-link i {
    display: inline-block;
    margin-top: 2px;
    margin-left: 5px;
}

.shop-item-card {
    border-radius: 10px;
    display: block;
    margin: 10px;
    -webkit-transition: all 150ms ease-in-out;
    -moz-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
    position: relative;
    text-align: center;
    padding: 15px;
    overflow: hidden;
}

.shop-item-card-bg .shop-item-card-name,
.shop-item-card-bg .shop-item-card-count {
    color: white !important;
}

.shop-item-card-logo {
    width: 100px;
    height: 100px;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
}

.shop-item-card-name {
    font-size: 18px;
    color: black;
    display: block;
    font-weight: 600;
}

.shop-item-card-button {
    margin-top: 20px;
}

.shop-item-card-count {
    display: block;
    color: #aeaeae;
    margin-top: 3px;
}

.shop-item-card-list {
    display: block;
    margin-bottom: 15px;
    -webkit-transition: all 150ms ease-in-out;
    -moz-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
    position: relative;
    border-bottom: 1px solid #f7f8fa;
    padding-bottom: 15px;
}

.shop-item-card-list-content-ad-img {
    height: 100px;
    overflow: hidden;
    border-radius: 8px;
    display: block;
    margin-bottom: 10px;
}

.shop-item-card-list-content-ad-title {
    display: block;
    color: black;
    margin-top: 5px;
    word-break: break-word;
}

.shop-item-card-list-count {
    font-size: 14px;
    margin-top: 5px;
    color: #aeaeae;
}

.shop-category-list,
.blog-category-list {
    margin-bottom: 15px;
}

.shop-category-list a,
.blog-category-list a {
    color: black;
    text-decoration: none;
    padding: 3px 0px;
    display: block;
}

.shop-category-list>a,
.blog-category-list>a {
    font-weight: bold;
}

.shop-category-list a:hover,
.shop-category-list a.active,
.blog-category-list a:hover,
.blog-category-list a.active {
    color: #0069D9;
}

.shop-category-list>ul ul,
.blog-category-list>ul ul {
    padding-left: 16px;
    list-style: none;
}

.shop-category-list>ul,
.blog-category-list>ul {
    padding-left: 0px;
    list-style: none;
    margin-bottom: 0px;
}

.shop-item-card-list-content-ad-price-now {
    display: inline-block;
    margin-right: 7px;
    color: black;
    font-weight: bold;
}

.shop-item-card-list-content-ad-price-old {
    display: inline-block;
    text-decoration: line-through;
    color: #ef4c4c;
}

.blog-category-list a i {
    visibility: hidden;
}

.blog-category-list a.active i {
    color: #0069D9;
    font-size: 18px;
    float: right;
    visibility: visible;
}

.shop-category-list a.active {
    color: #0069D9;
}

.shop-category-list a i {
    margin-left: 5px;
}

.shop-list-pages a {
    text-align: center;
    display: inline-block;
    padding: 10px 25px;
    background-color: #f7f8fa;
    border-radius: 25px;
    color: black;
    text-decoration: none;
    margin-right: 5px;
    margin-bottom: 5px;
}

.shop-list-pages a:hover,
.shop-list-pages a.active {
    color: black;
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.shop-list-pages {
    margin-bottom: 20px;
}

.shop-page-control {
    text-align: right;
    margin-bottom: 20px;
}

.shop-page-control span {
    display: inline-block;
    cursor: pointer;
    padding: 10px 25px;
    background-color: #f7f8fa;
    border-radius: 50px;
    margin-left: 5px;
}

.shop-page-control-delete {
    background-color: tomato !important;
    color: white !important;
}

figure table {
    width: 100% !important;
}

.ck-content .image-style-side {
    float: right;
    max-width: 50%;
}

.price_stock {
    display: none;
}

.shop-item-card-list-desc {
    margin-top: 10px;
}

.board-view-user-label-shop span {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 10px;
    padding: 2px 8px;
    border-radius: 10px;
    background: #E83587;
    color: white;
}

.main-search-results-link-shop {
    display: inline-block;
    margin-bottom: 0px;
    font-size: 11px;
    padding: 2px 8px;
    border-radius: 50px;
    background: rgba(120, 82, 249, 1.0);
    background: -webkit-linear-gradient(top left, rgba(120, 82, 249, 1.0), rgba(173, 99, 231, 1.0));
    background: -moz-linear-gradient(top left, rgba(120, 82, 249, 1.0), rgba(173, 99, 231, 1.0));
    background: linear-gradient(to bottom right, rgba(120, 82, 249, 1.0), rgba(173, 99, 231, 1.0));
    color: white;
}

.sidebar-style-link {
    text-align: center;
}

.sidebar-style-link>div {
    cursor: pointer;
    text-decoration: underline;
}

.sidebar-style-link>div:hover {
    text-decoration: none;
}

.mb20 {
    margin-bottom: 20px;
}

.menu-label-news {
    display: inline-block;
    margin-left: 5px;
    font-size: 10px;
    padding: 2px 8px;
    border-radius: 50px;
    background: rgba(120, 82, 249, 1.0);
    background: -webkit-linear-gradient(top left, rgba(120, 82, 249, 1.0), rgba(173, 99, 231, 1.0));
    background: -moz-linear-gradient(top left, rgba(120, 82, 249, 1.0), rgba(173, 99, 231, 1.0));
    background: linear-gradient(to bottom right, rgba(120, 82, 249, 1.0), rgba(173, 99, 231, 1.0));
    color: white !important;
    vertical-align: bottom;
}

.mini-list-ads-img {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 5px;
    margin-right: 15px;
    float: left;
}

.mini-list-ads-content {
    float: left;
    width: calc(100% - 75px);
    align-items: center;
    display: inline-flex;
    min-height: 50px;
}

.mini-list-ads {
    margin: 3px 0;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.mini-list-ads-price-old {
    text-decoration: line-through;
    color: red;
}

.mini-list-ads-price-now {
    margin-right: 10px;
    font-weight: bold;
}

.mini-list-ads:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.user-add-review-list-ads {
    max-height: 300px;
    overflow: auto;
}

.user-add-review-tab-2,
.user-add-review-tab-3,
.user-add-review-tab-4 {
    display: none;
}

.user-add-review-tab-4 .star-rating {
    font-size: 30px;
}

.user-add-review-attach {
    margin-top: 10px;
}

.user-add-review-attach-change {
    display: inline-block;
    border-bottom: 1px solid;
    cursor: pointer;
    font-weight: bold;
    font-size: 13px;
}

.attach-files-preview {
    width: 55px;
    height: 55px;
    overflow: hidden;
    border-radius: 10px;
    display: inline-block;
    margin-right: 5px;
    margin-top: 5px;
    position: relative;
}

.user-add-review-attach-files {
    margin-top: 10px;
}

.attach-files-loader img {
    opacity: 0.5;
}

.attach-files-delete,
.chat-dialog-attach-delete {
    position: absolute;
    top: 5px;
    right: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: #dc3545;
    color: white;
    border-radius: 50px;
    cursor: pointer;
}

.user-review-item-attach a {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    overflow: hidden;
    display: inline-block;
    margin-right: 5px;
    margin-top: 5px;
}

.cities_active_country {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.citiesLinkCountry {
    margin-bottom: 5px;
    font-weight: bold;
}

.map-search-instance {
    z-index: 0;
}

.leaflet-popup-content .map-search-offer-main {
    min-width: 450px;
}

.leaflet-popup-content {
    width: 100% !important;
    margin: 0px !important;
    padding: 15px !important;
}

.ads-create-main-category-list-item {
    text-align: center;
    position: relative;
    cursor: pointer;
    margin: 10px 0;
}

.ads-create-main-category-icon {
    text-align: center;
    display: inline-block;
}

.ads-create-main-category-icon img {
    height: 130px;
}

.ads-create-main-category-name {
    display: block;
    margin-top: 5px;
    color: black;
    font-size: 15px;
    font-weight: 400;
    padding: 0 5px;
}

.ads-create-main-category-icon-circle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-left: -50px;
    background-color: #E5F2F9;
    transition: all 0.5s ease;
    z-index: -1;
}

.ads-create-main-category-list-item:hover .ads-create-main-category-icon-circle {
    width: 120px;
    height: 120px;
    margin-left: -60px;
}

.ads-create-category-bg-selected .ads-create-main-category-list-item {
    opacity: 0.3;
}

.ads-create-main-category-change {
    opacity: 1 !important;
}

.ads-create-subtitle {
    font-size: 18px;
    font-weight: bold;
}

.ads-create-subcategory-list span {
    display: block;
    cursor: pointer;
    padding: 7px 10px;
    border-radius: 7px;
}

.ads-create-subcategory-list span:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.ads-create-textarea {
    border: 1px solid rgb(216, 216, 216) !important;
    border-radius: 10px;
    font-size: 15px;
    padding: 9px 10px;
    width: 100%;
}

.ads-create-input {
    border: 1px solid #ced4da !important;
    border-radius: 10px;
    font-size: 14px;
    padding: 0px 10px;
    height: 42px;
    width: 100%;
    background-color: #fff;
}

.ads-create-input:focus,
.ads-create-textarea:focus {
    border: 1px solid #007bff !important;
    outline: none;
}

.ads-create-main-data {
    margin-top: 30px;
    display: none;
    background-color: white;
}

.ads-create-subcategory-change {
    background-color: #E5F2F9;
}

.ads-create-subcategory-prev {
    display: inline-flex;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    background-color: white;
    margin-right: 10px;
    box-shadow: 0 0 30px rgb(0 0 0 / 15%);
}

.create-input-length {
    font-size: 12px;
    color: rgb(143, 143, 143);
    margin-top: 5px;
    margin-bottom: 0px;
}

.ads-create-list-filters-show {
    cursor: pointer;
    color: #007bff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ads-create-main-data-filters,
.ads-create-main-data-filters-list,
.ads-create-main-data-price,
.ads-create-main-data-online-view {
    display: none;
}

.input-dropdown {
    position: relative;
}

.input-dropdown-box {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
}

.input-dropdown-box input {
    padding-right: 20px;
}

.input-dropdown-box .uni-dropdown-bg {
    background-color: transparent;
    border: none;
    padding: 0px;
    height: 42px;
}

.input-dropdown-name-display {
    font-size: 15px;
    margin-right: 10px;
    margin-top: 10px;
}

.ads-create-main-data-price-variant {
    padding: 23px 15px;
    background-color: white;
    border: 1px solid white;
    border-radius: 10px;
    cursor: pointer;
    -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0 / 7%);
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 7%);
}

.ads-create-main-data-price-variant>div {
    height: 100%;
    width: 100%;
    display: inline-flex;
    align-items: center;
}

.ads-create-main-data-price-variant i {
    font-size: 30px;
}

.ads-create-main-data-price-variant-name {
    font-size: 14px;
    margin-left: 10px;
    display: inline-block;
}

.ads-create-main-data-price-variant:hover {
    background-color: #E5F5FF;
    box-shadow: none;
}

.ads-create-main-data-price-variant-active {
    background-color: #E5F5FF;
    box-shadow: none;
}

.ads-create-main-data-box-item {
    margin-top: 25px;
}

.ads-create-main-data .ads-create-main-data-box-item:nth-child {
    margin-top: 0px;
}

.ads-create-publish {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    height: 50px;
    margin-top: 30px;
    border: none;
    outline: none;
    border-radius: 10px;
    font-size: 14px;
}

.ads-create-publish:hover {
    opacity: 0.9;
}

.ads-create-main-data-user-options {
    background-color: #E5F5FF;
    padding: 25px;
    border-radius: 10px;
}

.ads-create-main-data-user-options strong {
    display: block;
    font-size: 15px;
}

.user-options-add-phone {
    display: block;
    margin-top: 10px;
    cursor: pointer;
}

.ads-item-user-card {
    border: 1px solid transparent;
    background: #F7F8FA;
    display: block;
}

.ads-item-user-card:hover {
    text-decoration: none !important;
}

.ads-item-user-card-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    background-color: #F7F8FA;
    margin-top: 40px;
    margin-bottom: 0px;
    position: relative;
}

.ads-item-user-card-name {
    display: block;
    font-size: 14px;
    color: black;
    margin-top: 5px;
    font-weight: bold;
    padding: 0 10px;
}

.ads-create-main-data-user-options-phone-2 {
    display: none;
}

.single-article-image {
    height: 100px;
    overflow: hidden;
    border-radius: 10px;
}

.single-article-title {
    margin-top: 10px;
    font-weight: bold;
    display: block;
}

.single-article {
    margin-bottom: 20px;
    display: block;
    color: black;
}

.ad-create-sidebar {
    position: relative;
    height: 100%;
}

.list-single-article {
    position: sticky;
    top: 100px;
}

.ads-update-category-box {
    position: relative;
    z-index: 5;
}

.ads-update-category-list {
    position: absolute;
    top: 0px;
    left: 0px;
    display: none;
    background-color: white;
    border-radius: 10px;
    width: 300px;
    box-shadow: 0 5px 13px 0 rgb(0 0 0 / 20%);
    padding: 10px 0;
}

.ads-update-category-box>span {
    cursor: pointer;
    font-weight: bold;
    color: #007bff;
}

.ads-update-category-list span {
    display: block;
    padding: 4px 15px;
    cursor: pointer;
    font-size: 15px;
}

.ads-update-category-list span:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.ads-update-category-list:before {
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 12px solid #F7F8FA;
    position: absolute;
    left: 20px;
    top: -12px;
    content: "";
}

.map-search-offer-main .ads-item-user-card-avatar {
    margin-top: 10px !important;
}

.form-search-subscribe {
    padding: 17px;
    border-radius: 10px;
    border: 1px solid transparent;
    text-align: center;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    max-width: 720px;
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.form-search-subscribe i {
    font-size: 55px;
    color: rgba(120, 82, 249, 1.0);
    margin-bottom: 10px;
    transform: rotate(5deg);
}

.form-search-subscribe .btn-custom {
    width: auto;
    margin-top: 20px;
}

.form-search-subscribe p {
    max-width: 400px;
    margin: 0 auto;
}

.item-grid-area {
    font-size: 13px;
    display: block;
    margin-top: 0px;
    color: gray;
}

.item-grid-date {
    font-size: 13px;
    display: block;
    margin-top: 0px;
    color: gray;
}

.uni-select-list-search {
    padding: 5px 10px;
    margin-bottom: 5px;
}

.uni-select-list-search input {
    display: block;
}

.ads-item-user-card-person {
    display: block;
    font-size: 13px;
    color: black;
    margin-top: 0px;
}

.header-wow {
    position: relative;
    z-index: 50;
}

.header-wow-top {
    padding: 15px 0;
    background-color: transparent;
    position: relative;
    border-bottom: 1px solid #f2f2f2;
    background-color: #fff;
}

.header-wow-sticky-mobile {
    background-color: white;
    z-index: 50;
    position: relative;
}

.header-wow-sticky-active {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    box-shadow: 0 2px 8px #00000014;
}

.header-wow-sticky {
    background-color: white;
    height: 80px;
    position: relative;
}

.header-wow-sticky-container {
    background-color: white;
    height: 80px;
    padding: 15px 0;
    border-bottom: 1px solid #e5e5e5;
}

.header-wow-sticky-search {
    position: relative;
}

.header-wow-sticky-search input[type='text'] {
    height: 48px;
    padding-left: 50px;
    padding-right: 15px;
    border-radius: 10px;
    border: 1px solid #f7f8fa;
    width: 100%;
    background-color: #f7f8fa;
    outline: none;
}

.sticky-search-control-geo,
.sticky-search-control-geo-area {
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    padding-right: 15px;
    display: inline-flex;
    align-items: center;
    background-color: #f7f8fa;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    padding-left: 15px;
}

.sticky-search-control-geo>div {
    display: inline-block;
}

.input-separator {
    width: 1px;
    background-color: #00000029;
    height: 20px;
    margin: 0 7px;
}

.header-wow-sticky-search input:focus {
    border: 1px solid #007bff !important;
    outline: none;
}

.header-wow-sticky-search-icon {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    background-color: transparent;
    padding-top: 3px;
}

.header-wow-sticky-search-icon i {
    font-size: 22px;
}

.sticky-search-control-geo-name {
    color: #0066f5;
    font-weight: 500;
    cursor: pointer;
    font-size: 15px;
}

.sticky-search-control-geo-area-change,
.sticky-search-control-geo-cancel,
.sticky-search-control-geo-area-cancel {
    color: #0066f5;
    font-weight: 500;
    cursor: pointer;
    font-size: 15px;
    position: relative;
}

.sticky-search-control-geo-name:hover,
.sticky-search-control-geo-area-change:hover,
.sticky-search-control-geo-cancel:hover,
.sticky-search-control-geo-area-cancel:hover {
    color: #f95146;
}

.sticky-search-control-geo-name i {
    font-size: 18px;
}

.sticky-search-geo-list,
.sticky-search-geo-results,
.sticky-search-geo-area-list {
    position: absolute;
    top: 60px;
    left: 0px;
    padding: 25px;
    box-shadow: 0 6px 32px #5e5c5c24;
    border-radius: 10px;
    background-color: white;
    width: 700px;
}

.sticky-search-geo-list .item-city,
.sticky-search-geo-list .item-city-country {
    display: block;
    color: black;
    font-size: 14px;
    color: black;
    cursor: pointer;
    margin: 7px 0px;
    border-radius: 5px;
    font-weight: 500;
}

.sticky-search-geo-list .item-city:hover,
.sticky-search-geo-list .item-city-country:hover {
    color: #f95146;
}

.header-wow-sticky-menu {
    display: inline-flex;
    height: 45px;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    padding: 0 15px;
    cursor: pointer;
    width: 100%;
}

.header-wow-sticky-menu i {
    font-size: 19px;
    margin-right: 5px;
    margin-top: 2px;
}

.header-wow-sticky-menu .la-times {
    display: none;
}

.header-wow-top-list a {
    display: inline-block;
    color: black;
    margin-right: 30px;
    font-weight: 500;
    height: 100%;
}

.header-wow-top-list a:last-child {
    margin-right: 0px !important;
}

.header-wow-top-geo {
    display: inline-flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
    color: #565656;
    font-weight: 600;
    width: 100%;
}

.header-wow-top-geo span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
}

.header-wow-top-geo:hover {
    color: black;
}

.header-wow-sticky-add {
    border-radius: 10px;
    padding: 0 15px;
    display: inline-flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: linear-gradient(94.43deg, #02AAEC 5.35%, #43E9E4 121.08%);
    color: white;
}

.header-wow-sticky-auth {
    border-radius: 10px;
    padding: 0 15px;
    display: inline-flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: #f7f8fa;
    color: black;
}

.header-wow-top-list a:hover {
    color: black;
}

.header-wow-sticky-list>div {
    margin-right: 15px;
}

.header-wow-sticky-list>div:last-child {
    margin-right: 0px !important;
}

.header-wow-sticky-list>div a {
    color: #565656;
    font-size: 15px;
}

.header-wow-sticky-list>div a:hover {
    text-decoration: none;
    color: black;
}

.header-wow-sticky-add:hover {
    text-decoration: none;
    opacity: 0.9;
    color: white;
}

.header-wow-sticky-auth:hover {
    text-decoration: none;
    opacity: 0.9;
}

.site-color-dark {
    background-color: white;
    color: #212228;
}

.site-color-white {
    background-color: #212228;
    color: white;
}

.header-wow-top-list-admin {
    position: relative;
    min-height: 25px;
}

.header-wow-top-list-admin:hover {
    border: 1px dashed red;
    border-radius: 10px;
}

.header-wow-top-list-admin:hover .header-wow-top-list-admin-edit {
    display: block;
}

.header-wow-top-list-admin-edit {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 3px 10px;
    background-color: red;
    color: white;
    font-size: 12px;
    display: none;
    cursor: pointer;
}

.modal-edit-site-menu-list>div {
    margin-bottom: 10px;
}

.modal-edit-site-menu-delete i {
    font-size: 20px;
    color: red;
}

.modal-edit-site-menu-delete {
    cursor: pointer;
    display: block;
    margin-top: 8px;
}

.header-wow-sticky-mobile-geo {
    height: 40px;
    padding-top: 17px;
    padding-bottom: 10px;
}

.floating-menu-ad {
    position: fixed;
    left: 10px;
    bottom: 10px;
    right: 10px;
    z-index: 150;
    background-color: white;
    padding: 15px;
    box-shadow: 0 6px 32px #5e5c5c24;
    border-radius: 50px;
}

.floating-menu-box {
    background-color: white;
    padding: 0 20px;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    box-shadow: 0 6px 32px #5e5c5c24;
    border-radius: 10px;
    height: 75px;
    margin: 0 auto;
}

.floating-menu {
    position: fixed;
    bottom: 10px;
    z-index: 20;
    background-color: transparent;
    padding: 0 10px;
    left: 0;
    right: 0;
    height: 75px;
    text-align: center;
}

.floating-menu-box>a {
    text-decoration: none;
    text-align: center;
    color: #a1a8bd;
    position: relative;
    height: 100%;
    display: inline-flex;
    align-items: center;
    margin: 0 15px;
    min-width: 55px;
    justify-content: center;
}

.floating-menu-box>a:hover {
    color: #f95146;
}

.floating-menu-icon-title {
    display: block;
    font-size: 11px;
    margin-top: 5px;
}

.floating-menu-icon {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
}

.floating-menu-ad-create {
    color: red !important;
}

.BadgePulse {
    animation: BadgePulse 1.8s infinite
}

@-webkit-keyframes BadgePulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(254, 25, 94, .4)
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(254, 25, 94, 0)
    }

    100% {
        -webkit-box-shadow: 0 0 0 20px rgba(254, 25, 94, 0)
    }
}

@keyframes BadgePulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(254, 25, 94, .4);
        box-shadow: 0 0 0 0 rgba(254, 25, 94, .4)
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(254, 25, 94, 0);
        box-shadow: 0 0 0 10px rgba(254, 25, 94, 0)
    }

    100% {
        -moz-box-shadow: 0 0 0 20px rgba(254, 25, 94, 0);
        box-shadow: 0 0 0 20px rgba(254, 25, 94, 0)
    }
}

.toolbar-theme-toggle {
    border: 1px solid #939393;
    border-radius: 50px;
    width: 57px;
    height: 31px;
    padding: 2px;
}

.toolbar-theme-toggle>span {
    float: left;
    display: inline-flex;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: #939393;
}

.toolbar-theme-toggle>span i {
    font-size: 20px;
}

.toolbar-theme-toggle-active {
    background-color: #212228;
    color: white !important;
}

.toolbar-theme-toggle-active-dark {
    background-color: #939393;
    color: white !important;
}

.toolbar-link-icon i {
    font-size: 37px;
}

.toolbar-link-title-icon {
    text-align: center;
    color: #001a34;
}

.toolbar-link-title-icon-name {
    display: block;
    font-size: 13px;
    margin-top: 3px;
}

.toolbar-link-title-icon:hover,
.toolbar-link-title-icon-box i:hover {
    color: #f95146 !important;
    text-decoration: none;
}

.mr15 {
    margin-right: 15px;
}

.mr20 {
    margin-right: 20px;
}

.header-wow-mobile-category-image img {
    height: 40px;
}

.header-wow-mobile-category-image {
    width: 40px;
    height: 40px;
    text-align: center;
    margin-bottom: 5px;
    display: inline-block;
}

.header-wow-mobile-all-category-image {
    width: 40px;
    height: 40px;
    text-align: center;
    margin-bottom: 5px;
    background-color: #eaffd5;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.header-wow-mobile-all-category:hover {
    color: #f95146;
    cursor: pointer;
}

.header-wow-mobile-category a {
    color: black;
    display: inline-block;
    text-align: center;
    width: 75px;
    scroll-snap-align: start;
    margin: 0 5px;
    text-decoration: none;
    display: -webkit-box;
    font-size: 12px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;

}

.header-wow-mobile-category a:hover {
    color: #f95146;
}

.mobile-box-register-bonus {
    position: fixed;
    bottom: 100px;
    right: 15px;
    background: linear-gradient(94.43deg, #02AAEC 5.35%, #43E9E4 121.08%);
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    width: 200px;
    box-shadow: 0 1px 15px 1px rgb(52 40 104 / 8%);
    z-index: 30;
    display: none;
}

.mobile-box-register-bonus p {
    font-size: 12px;
    color: white;
}

.mobile-box-register-bonus h5 {
    font-size: 14px;
    color: white;
    font-weight: 500;
}

.mobile-box-register-bonus-close {
    position: absolute;
    top: -15px;
    right: -15px;
}

.mobile-box-register-bonus-close i {
    cursor: pointer;
    font-size: 17px;
    color: black;
}

.mobile-box-register-bonus .btn-custom {
    font-size: 12px;
}

.ad-view-subtitle-bold {
    font-size: 20px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 15px;
}

.ad-view-box-info {
    margin-top: 25px;
}

.ymaps-2-1-79-balloon-content__header {
    display: none !important;
}

.btn-footer-icon-gradient {
    color: white;
    background: linear-gradient(94.43deg, #02AAEC 5.35%, #43E9E4 121.08%);
}

.btn-footer-icon-gradient i {
    font-size: 22px;
    margin-right: 5px;
    display: inline-block;
}

.btn-footer-icon-gradient:hover {
    text-decoration: none;
    opacity: 0.9;
    color: white;
}

.ads-view-open-map {
    margin-top: 10px;
    display: block;
}

.catalog-filters-top {
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    margin-top: 25px;
}

.catalog-filters-top-more-filters {
    position: relative;
    z-index: 10;
}

.catalog-filters-top-more-filters-items {
    position: absolute;
    top: 55px;
    right: 0px;
    background-color: white;
    box-shadow: 0 1px 15px 1px rgb(52 40 104 / 8%);
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    left: 50%;
    margin-left: -150px;
    display: none;
}

.catalog-filters-top-scroll {
    max-height: 450px;
    overflow: auto;
}

.catalog-list-options:hover .catalog-list-options-name {
    color: #6B6FFF;
}

.catalog-filters-top-item-price a {
    display: inline-block;
    background: rgb(235, 235, 235);
    color: black !important;
    padding: 4px 12px;
    border-radius: 25px;
    margin-right: 5px;
    margin-top: 5px;
}

.catalog-filters-top-count {
    display: inline-flex;
    width: 17px;
    height: 17px;
    background-color: red;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 12px;
    margin-left: 5px;
}

.sidebar-cart {
    position: fixed;
    top: 0px;
    right: -500px;
    bottom: 0px;
    background-color: white;
    width: 500px;
    z-index: 100;
    box-shadow: 0 0 30px rgb(0 0 0 / 15%);
}

.sidebar-cart-header {
    background-color: #f7f8fa;
    padding: 20px;
    position: relative;
}

.sidebar-cart-header p {
    margin: 0px;
}

.sidebar-cart-header h5 {
    margin-top: 0;
    font-weight: bold;
    font-size: 21px;
}

.sidebar-cart-content {
    padding: 20px;
    position: relative;
    height: 100%;
}

.toolbar-link-cart {
    position: relative;
}

.label-count-cart,
.label-count-message {
    border: 3px solid white;
    vertical-align: text-bottom;
    position: absolute;
    top: -5px;
    right: -13px;
    font-size: 10px;
    background-color: #F75059;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: white !important;
}

.sidebar-cart-bg {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 15px;
    overflow: auto;
    display: inline-flex;
    align-items: center;
}

.modal-cart-header p {
    margin: 0px;
}

.modal-cart-header h5 {
    margin-top: 0;
    font-weight: bold;
    font-size: 21px;
}

.cart-goods-item {
    margin: 15px 0;
}

.cart-goods-item-image {
    overflow: hidden;
    height: 75px;
    border-radius: 10px;
}

.cart-goods-item-content>a {
    display: block;
    color: black;
    font-weight: bold;
}

.cart-goods-item-delete i,
.cart-page-goods-item-delete i {
    cursor: pointer;
    font-size: 19px;
    color: #919191;
}

.cart-goods-item-delete i:hover,
.cart-page-goods-item-delete i:hover {
    color: black;
}

.cart-empty-icon {
    margin-top: 50px;
}

.cart-empty-icon i {
    font-size: 55px;
    margin-bottom: 15px;
    color: #8F949B;
    background: rgba(120, 82, 249, 1.0);
    background: -webkit-linear-gradient(top left, rgba(120, 82, 249, 1.0), rgba(173, 99, 231, 1.0));
    background: -moz-linear-gradient(top left, rgba(120, 82, 249, 1.0), rgba(173, 99, 231, 1.0));
    background: linear-gradient(to bottom right, rgba(120, 82, 249, 1.0), rgba(173, 99, 231, 1.0));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.cart-empty {
    text-align: center;
    margin-bottom: 50px;
}

.cart-buttons {
    padding-top: 15px;
}

.sidebar-cart-close {
    position: absolute;
    top: 33px;
    right: 15px;
    cursor: pointer;
}

.sidebar-cart-close i {
    font-size: 27px;
    color: #919191;
}

.sidebar-cart-close i:hover {
    color: black;
}

.cart-container {
    height: 100%;
}

.cart-goods {
    height: calc(100% - 150px);
    overflow-x: hidden;
    overflow-y: auto;
}

.cart-not-available {
    display: block;
    color: red;
}

.cart-goods-item-content-price {
    display: block;
    font-size: 13px;
    margin-bottom: 0px;
}

.cart-goods-item-content-price-total {
    font-weight: bold;
}

.input-group .cart-goods-item-count {
    max-width: 50px;
}

.header-cart {
    margin-top: 30px;
    margin-bottom: 30px;
}

.title-bold {
    font-weight: 700;
}

.cart-info-auth {
    border-radius: 20px;
    padding: 25px;
    background-color: #9b38dc;
    color: white;
    margin-bottom: 15px !important;
}

.cart-info-auth h4 {
    margin-top: 0px;
    font-size: 20px;
    font-weight: 700;
}

.cart-info-auth p {
    margin: 0px;
}

.cart-info-auth button {
    border-radius: 50px;
    background-color: white;
    padding: 7px 25px;
    cursor: pointer;
    display: block;
    outline: none;
    margin-top: 20px;
    border: 1px solid transparent;
}

.cart-info-auth button i {
    font-size: 20px;
    vertical-align: bottom;
}

.cart-info-auth button:hover {
    background-color: transparent;
    border: 1px solid white;
    color: white;
}

.cart-page-container {
    border-radius: 10px;
    padding: 25px;
    background-color: #f7f8fa;
}

.cart-goods-item-content-seller {
    display: block !important;
    margin-bottom: 10px !important;
    font-size: 13px !important;
    color: #0069D9 !important;
}

.cart-page-sidebar-name {
    color: #565656;
}

.cart-page-sidebar-value {
    color: #565656;
    font-weight: bold;
    text-align: right;
    display: block;
}

.cart-page-link-clear {
    display: inline-block;
    cursor: pointer;
    margin-top: 5px;
    color: #565656;
}

.cart-page-link-clear:hover {
    color: black;
}

.item-list-content-goods>div {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    display: inline-block;
    margin-right: -15px;
    position: relative;
    border: 1px solid #f7f8fa;
}

.item-list-content-goods {
    margin-top: 15px;
}

.item-list-order-secure {
    color: green;
}

.board-view-ads-right-span span {
    font-size: 13px;
    margin-right: 10px;
}

.cart-box-2 {
    display: none;
}

.order-messages-box {
    position: relative;
    margin-bottom: 15px;
}

.order-message-attach-change {
    display: inline-block;
    border-bottom: 1px solid;
    cursor: pointer;
    font-weight: bold;
    margin-top: 10px;
    font-size: 13px;
    margin-bottom: 10px;
}

.order-messages-item {
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 15px;
    display: inline-block;
}

.order-messages-item-message {
    display: block;
    margin-top: 10px;
}

.order-messages-item-name {
    display: inline-block;
    font-weight: bold;
    color: black;
    margin-right: 15px;
}

.order-messages-item-date {
    float: right;
    color: #8c8c8c;
    font-size: 12px;
    margin-top: 2px;
}

.order-message-item-attach a {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    overflow: hidden;
    display: inline-block;
    margin-right: 10px;
    margin-top: 10px;
}

.order-messages-from {
    background-color: #f7f8fa;
}

.order-messages-to {
    background-color: #eaf2ff;
}

.order-messages-item-empty i {
    display: block;
    font-size: 22px;
}

.order-messages-item-empty {
    text-align: center;
}

.order-send-message {
    border: 1px solid rgb(216, 216, 216) !important;
    font-size: 14px !important;
    padding: 9px 10px;
    border-radius: 10px;
    width: 100%;
    min-height: 80px;
    max-height: 300px;
}

.order-send-message:focus {
    border: 1px solid #007bff !important;
    outline: none !important;
}

.secure-status-label-0 {
    background-color: #ffc107;
    color: #212529;
}

.secure-status-label-1 {
    background-color: #28a745;
    color: white;
}

.secure-status-label-2 {
    background-color: #ffc107;
    color: #212529;
}

.secure-status-label-3 {
    background-color: #28a745;
    color: white;
}

.secure-status-label-4 {
    background-color: #ffc107;
    color: #212529;
}

.secure-status-label-5 {
    background-color: #dc3545;
    color: white;
}

.left-list-category a {
    color: #0066f5;
    text-decoration: none;
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
}

.left-list-category a:hover {
    color: #f95146;
}

.vip-block {
    border-radius: 10px;
    padding: 25px;
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.header-wow-top-lang {
    position: relative;
    height: 100%;
}

.header-wow-top-lang-name {
    text-decoration: underline;
    cursor: pointer;
}

.toolbar-link-title-icon-box {
    position: relative;
    display: inline-block;
}

.toolbar-link-title-icon-box i {
    font-size: 24px;
    color: black;
}

.toolbar-link-profile .toolbar-dropdown-box {
    top: 55px;
}

.header-wow-top-lang .toolbar-dropdown-box {
    top: 25px;
    z-index: 1;
}

.slick-slide {
    margin: 0 10px;
    width: 310px;
}

.slider-item-grid .item-grid {
    max-width: 257px;
}

.init-slider-grid-next,
.init-slider-grid-prev,
.catalog-subcategory-slider-next,
.catalog-subcategory-slider-prev {
    background: white;
    width: 35px;
    height: 35px;
    border-radius: 10px;
    color: black;
    box-shadow: rgba(0, 0, 0, .06) 0 4px 8px 0;
    position: absolute;
    top: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
    opacity: .95;
    margin-top: -17px;
}

.init-slider-grid-prev i,
.init-slider-grid-next i,
.catalog-subcategory-slider-next i,
.catalog-subcategory-slider-prev i {
    font-size: 20px;
}

.init-slider-grid-prev:hover,
.init-slider-grid-next:hover,
.catalog-subcategory-slider-next:hover,
.catalog-subcategory-slider-prev:hover {
    opacity: 1;
}

.container-search-geo,
.sticky-search-geo-area-list,
.sticky-search-control-geo-area {
    display: none;
}

.sticky-search-geo-results,
.modal-search-geo-results {
    display: none;
    padding: 15px;
    max-height: 400px;
    margin-bottom: auto;
    overflow: auto;
}

.sticky-search-geo-results>div,
.modal-search-geo-results>div {
    padding: 6px 15px;
    margin-left: -15px;
    margin-right: -15px;
    display: block;
    cursor: pointer;
}

.sticky-search-geo-results>div strong,
.modal-search-geo-results>div strong {
    font-size: 14px;
    display: block;
}

.sticky-search-geo-results>div:hover,
.modal-search-geo-results>div:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.header-wow-mobile {
    position: relative;
    z-index: 50;
    overflow: hidden;
    margin-bottom: 15px;
}

.height200 {
    height: 200px;
}

.height55 {
    height: 55px;
}

.header-wow-mobile-category-slider {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    padding-top: 20px;
    padding-bottom: 10px;
}

.header-wow-mobile-top {
    background-color: white;
    position: relative;
    height: 55px;
    z-index: 2;
    box-shadow: 0 2px 8px #00000014;
}

.header-wow-mobile-top .toolbar-link {
    height: 55px;
    display: inline-flex;
    align-items: center;
}

.header-wow-mobile-category {
    background-color: white;
    transition: transform .2s ease-in-out;
    z-index: 1;
}

.header-wow-mobile-sticky-search input[type='text'] {
    height: 40px;
    padding-left: 40px;
    padding-right: 15px;
    border: 1px solid #f7f8fa;
    width: 100%;
    background-color: #f7f8fa;
    outline: none;
    border-radius: 10px;
}

.header-wow-mobile-sticky-search input:focus {
    border: 1px solid white !important;
    outline: none;
}

.header-wow-mobile-sticky-search-icon {
    position: absolute;
    top: 0px;
    bottom: 0px;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 11px;
    background-color: transparent;
}

.header-wow-mobile-sticky-search-icon i {
    font-size: 18px;
}

.header-wow-mobile-sticky {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
}

.header-wow-mobile-category-hide {
    transition: top 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
}

.mobile-fixed-menu {
    position: fixed;
    z-index: 200;
    background-color: white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
}

.mobile-fixed-menu-header {
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: 50px;
}

.mobile-fixed-menu-content {
    overflow: auto;
    height: calc(100% - 50px);
    padding: 0 15px;
}

.mobile-fixed-menu-header-title {
    font-weight: 600;
}

.mobile-fixed-menu-header-close i {
    font-size: 25px;
    cursor: pointer;
}

.mobile-fixed-menu-header-close {
    margin-right: 15px;
}

.mobile-fixed-menu-content-link a {
    display: block;
    width: 100%;
    padding: 8px 15px;
    text-decoration: none;
    color: black;
}

.mobile-fixed-menu-content-link a:hover,
.mobile-fixed-menu_prev-category:hover,
.mobile-fixed-menu-user-link a:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.mobile-fixed-menu-user-link a {
    display: block;
    width: 100%;
    padding: 8px 15px;
    text-decoration: none;
    color: black;
}

.deny-margin-15 {
    margin: 0 -15px;
}

.mobile-fixed-menu_count-category {
    display: inline-block;
    font-weight: 500;
    float: right;
    font-size: 13px;
}

.mobile-fixed-menu-header-close:hover {
    color: #f95146;
}

.mobile-fixed-menu_prev-category {
    cursor: pointer;
    font-weight: 500;
    display: block;
    padding: 8px 15px;
    color: black;
}

.gray-select {
    background-color: #f7f8fa;
    padding: 6px 20px 6px 10px;
    outline: none;
    border: 1px solid #f7f8fa;
    border-radius: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cpath fill='%23000' fill-rule='evenodd' class='cls-1' d='M8,0L4.141,6.993,0.012,0.156Z'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: right 8px center;
}

.mobile-fixed-menu-content-card-user {
    padding: 25px;
    border-radius: 10px;
    text-align: center;
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.medium-avatar-img {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    overflow: hidden;
    border-radius: 50%;
    height: 60px;
    background-color: #dedede;
}

.medium-avatar-img img {
    height: 60px;
}

.medium-avatar-img i {
    font-size: 35px;
}

.card-user-link-profile:hover,
.card-user-link-logout:hover {
    opacity: 0.9;
    text-decoration: none;
    background-color: #917dfa;
    color: white !important;
}

.card-user-link-profile {
    text-align: center;
    padding: 10px 25px;
    display: inline-block !important;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 14px;
    background-color: #917dfa;
    color: white !important;
    width: auto;
}

.card-user-link-logout {
    text-align: center;
    padding: 10px 10px;
    display: inline-block !important;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 14px;
    background: rgb(235, 235, 235);
    color: black !important;
    width: auto;
}

.mobile-fixed-menu-content-card-user h6 {
    font-weight: 600;
}

.mobile-fixed-menu-content-bg-white {
    background-color: white;
    padding: 0 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 15px;
    padding-bottom: 10px;
}

.mobile-fixed-menu-content-footer-link {
    margin-top: 15px;
    padding: 0 15px;
    margin-bottom: 15px;
}

.mobile-fixed-menu-content-footer-link a {
    display: block;
    margin: 5px 0px;
    color: black;
}

.bg-gray {
    background-color: #f7f8fa;
}

.favorite-icon-active {
    color: red;
}

.header-ad-view-buttons {
    height: 100%;
    display: inline-flex;
    align-items: center;
}

.header-ad-view-buttons-button {
    display: inline-flex !important;
    text-align: center;
    padding: 8px 10px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 14px;
}

.header-ad-view-buttons-button i {
    font-size: 20px;
}

.header-ad-view-buttons-button:hover {
    opacity: 0.9;
    text-decoration: none;
}

.ml10 {
    margin-left: 10px;
}

.container-search-goods-flex {
    display: inline-flex;
    align-items: center;
    height: 100%;
    width: 100%;
}

.container-search-goods {
    width: 100%;
}

.span-style-strong {
    font-weight: 600;
}

.ad-view-title-info {
    margin-top: 10px;
}

.ad-view-title-info-label {
    margin-top: 0px;
    color: #999;
    font-size: 13px;
    display: inline-block;
    margin-right: 15px;
}

.ad-view-title-info-label-link {
    color: #0066f5;
    cursor: pointer;
    margin-right: 15px;
    font-weight: 500;
}

.ad-view-title-info-label-link:hover {
    color: #024bce;
}

.view-list-status-promo {
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.ad-view-promo-status-online {
    background-color: white;
    border: 1px solid #efefef;
}

.ad-view-promo-status-auction {
    background-color: white;
    border: 1px solid #efefef;
}

.ad-view-promo-status-booking {
    background-color: white;
    border: 1px solid #efefef;
}

.view-list-status-promo h5 {
    font-size: 16px;
    font-weight: 600;
}

.view-list-status-promo p {
    font-size: 14px;
    margin-bottom: 5px;
}

.ad-view-promo-status-online .view-list-status-promo-icon {
    background: url('../../images/camera_icon.png');
}

.ad-view-promo-status-auction .view-list-status-promo-icon {
    background: url('../../images/dollar_icon.png');
}

.ad-view-promo-status-booking .view-list-status-promo-icon {
    background: url('../../images/schedule_icon.png');
}

.view-list-status-promo-icon {
    width: 64px;
    height: 64px;
    display: block;
    margin: 0 auto;
}

.view-list-status-promo-button {
    cursor: pointer;
    text-decoration: underline;
    display: block;
    font-size: 13px;
}

.view-list-status-promo-button:hover {
    text-decoration: none;
}

.ad-view-promo-status-auction hr {
    display: none;
}

.lSSlideOuter .lSPager.lSGallery img {
    height: 100% !important;
    display: inline-block !important;
    width: auto !important;
}

.lSSlideOuter .lSPager.lSGallery a {
    max-width: auto !important;
}

.lSSlideOuter .lSPager.lSGallery li {
    background-color: #f7f8fa;
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
    border: 2px solid #f7f8fa;
}

.lSNext i,
.lSPrev i {
    font-size: 18px;
}

.lSNext,
.lSPrev {
    padding: 0;
    width: 35px;
    height: 35px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #e8edef;
    border-radius: 50%;
    color: #000 !important;
    background-color: #fff;
    cursor: pointer;
    outline: 0 !important;
}

.lSSlideOuter .lSPager.lSGallery li.active,
.lSSlideOuter .lSPager.lSGallery li:hover {
    border: 2px solid #0066f5;
    border-radius: 10px;
}

.header-wow-mobile-view-info {
    background-color: #f7f8fa;
}

.ad-view-title-favorite {
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
}

.ad-view-title-favorite:hover {
    color: #f95146;
}

.ad-view-title-favorite-icon {
    display: inline-block;
}

.ad-view-card-user {
    padding: 15px;
    border-radius: 10px;
    background-color: #f7f8fa;
    margin-bottom: 10px;
}

.ajax-container-similar {
    padding: 25px 0;
    background-color: #F7F8FA;
    display: none;
}

.ajax-container-similar h1 {
    margin-top: 0px !important;
}

.ad-gallery-hover-slider {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: none;
    z-index: 0;
}

.ad-gallery-hover-slider span {
    height: 178px;
    border-bottom: 2px solid #fff;
    margin: 0 3px;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: end;
    align-items: flex-end;
}

.map-search-offer-img .ad-gallery-hover-slider span {
    height: 99px !important;
}

.item-list-img .ad-gallery-hover-slider span {
    height: 168px !important;
}

.ad-gallery-hover-slider span:hover {
    border-bottom: 2px solid #007bff
}

.item-grid-img:hover .ad-gallery-hover-slider,
.map-search-offer-img:hover .ad-gallery-hover-slider,
.item-list-img:hover .ad-gallery-hover-slider {
    display: flex
}

.ad-gallery-touch-slide {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    height: 100%;
}

.ad-gallery-touch-slide img {
    height: 100%;
}

.ad-gallery-touch-slide img:not(:last-child) {
    margin-right: 5px;
}

.board-view-sidebar-box-stimulate {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
    padding: 20px;
    border-radius: 10px;
    margin-top: 15px;
    text-align: center;
}

.box-stimulate-title {
    font-weight: 600;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 5px;
}

.box-stimulate-count {
    font-size: 24px;
    margin-bottom: 0;
    margin-top: 0;
}

.ad-view-card-user-link-profile {
    font-size: 18px;
}

.category-label-count {
    font-size: 12px;
}

.main-search-shop {
    width: 100%;
}

.main-search-shop>div {
    position: relative;
}

.main-search-shop input {
    width: 100%;
    height: 48px;
    border: none;
    padding-left: 20px;
    padding-right: 80px;
    transition: all .15s linear;
    border-radius: 10px;
    background-color: #f7f8fa;
}

.main-search-shop-action {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}

.main-search-shop button {
    padding: 0 13px;
    height: 100%;
    display: inline-flex;
    align-items: center;
}

.main-search-shop input:focus {
    outline: none;
    border-color: #007bff;
}

.main-search-shop button i {
    font-size: 22px;
}

.main-search-shop button:focus,
.main-search-shop button:active {
    outline: none !important;
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.main-search-results-shop {
    width: 100%;
}

.mobile-fixed-button-cart {
    position: fixed;
    top: 30%;
    right: 0px;
    display: inline-block;
    padding: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #f7f8fa;
    text-align: center;
    z-index: 20;
    cursor: pointer;
}

.mobile-fixed-button-cart div {
    display: block;
    position: relative;
    color: black;
    font-size: 13px;
}

.mobile-fixed-button-cart svg {
    color: black;
}

.mobile-fixed-button-cart:hover svg,
.mobile-fixed-button-cart:hover div {
    color: #f95146 !important;
    text-decoration: none;
}

.header-button-menu-catalog {
    cursor: pointer;
    border-radius: 10px;
    padding: 0 15px;
    display: inline-flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.header-flex-box {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}

.header-flex-box-1 {
    margin-left: 15px;

}

.header-flex-box-2 {
    margin-left: 15px;
    flex-grow: 1;
}

.header-flex-box-3 {
    margin-left: 15px;
    margin-right: 15px;
}

.header-flex-box-mobile-2 {
    flex-grow: 1;
    margin-left: 15px;
    margin-right: 15px;
}

.header-button-menu-catalog:hover {
    opacity: 0.9;
    text-decoration: none;
}

.header-button-menu-catalog svg {
    margin-right: 5px;
}

.header-big-menu {
    background-color: white;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    z-index: 100;
    display: none;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
}

.header-big-category-menu-left {
    padding: 20px;
    color: white;
    border-right: 1px solid #f2f2f2;
    height: 400px;
    overflow: auto;
}

.header-big-category-menu-right {
    background-color: white;
    padding: 30px;
    width: 100%;
    height: 400px;
    overflow: auto;
}

.category-menu-left-image img {
    width: 32px;
}

.header-big-category-menu-left a {
    color: black;
    display: block;
    padding: 2px 0;
}

.category-menu-left-image {
    width: 32px;
    height: 32px;
    float: left;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.category-menu-left-name {
    width: calc(100% - 42px);
    display: inline-block;
    margin-top: 5px;
}

.header-big-category-menu-left a:hover .category-menu-left-name {
    color: #6B6FFF;
}

.header-big-subcategory-menu-list {
    display: none;
}

.header-big-subcategory-menu-list a {
    color: #3D3D3D;
}

.header-big-subcategory-menu-list a:hover {
    color: #6B6FFF;
    text-decoration: none;
}

.header-big-subcategory-menu-list h4 {
    margin-top: 0px;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
}

.header-big-subcategory-menu-list a {
    margin: 4px 0;
    display: block;
}

.header-button-menu-catalog-icon-2 {
    display: none;
}

.header-button-menu-catalog-active .header-button-menu-catalog-icon-2 {
    display: block;
}

.header-button-menu-catalog-active .header-button-menu-catalog-icon-1 {
    display: none;
}

.header-big-category-count {
    font-size: 13px;
    margin-left: 5px;
}

.col-xs-5th,
.col-sm-5th,
.col-md-5th,
.col-lg-5th {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
    width: 20%;
    float: left;
}

.col-5-th {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}

@media (min-width: 768px) {
    .col-sm-5th {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 20%;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@media (min-width: 992px) {
    .col-md-5th {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 20%;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@media (min-width: 1200px) {
    .col-lg-5th {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 20%;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@media only screen and (max-width: 992px) {
    .col-md-5th {
        width: 33%;
        float: none;
        display: block;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33%;
        -ms-flex: 0 0 33%;
        flex: 0 0 33%;
        max-width: 33%;
    }

    .col-md-5th.col-sm-5th {
        width: 20%;
        float: left;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 20%;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@media only screen and (max-width: 769px) {
    .col-md-5th {
        width: 100%;
        float: none;
        display: block;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-5th.col-sm-5th {
        width: 50%;
        float: none;
        display: block;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .col-md-5th {
        width: 100%;
        float: none;
        display: block;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .col-md-5th.col-sm-5th {
        width: 100%;
        float: none;
        display: block;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.catalog-subcategory a.active {
    font-weight: bold;
}

.shop-category-list-count {
    font-size: 13px;
    margin-left: 5px;
}

.select-box-subcategory .uni-select,
.select-box-city-options .uni-select {
    margin-bottom: 10px;
}

.select-box-city-options {
    margin-top: 10px;
}

.modal-form-filter .filter-items-spacing {
    margin-bottom: 10px;
}

.select-box-subcategory {
    margin-top: 10px;
}

.modal-search-geo-results {
    position: absolute;
    top: 50px;
    left: 0px;
    right: 0px;
    box-shadow: 0 6px 32px #5e5c5c24;
    border-radius: 10px;
    background-color: white;
    z-index: 10;
}

.modal-search-geo-container {
    position: relative;
}

.map-back-site-link a {
    color: black;
}

.auth-block .input-style2-custom {
    background-color: white;
    border: 1px solid white;
}

.item-img-no-photo {
    text-align: center;
    height: 100%;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #d6d5d5;
    font-size: 23px;
    text-decoration: none;
}

.board-view-sidebar hr {
    height: 2px;
    background-color: #eeeeee;
    color: #eeeeee;
    border: none;
}

.user-data-item .user-info {
    background-color: white;
    border: 1px solid #F7F8FA;
}

.input-phone-format {
    position: relative;
}

.input-phone-format-box {
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    background-color: #f7f8fa;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 0 5px;
    cursor: pointer;
    z-index: 10;
}

.input-phone-format-list {
    position: absolute;
    top: 35px;
    background-color: white;
    border-radius: 10px;
    right: 0px;
    box-shadow: 0 6px 32px #5e5c5c24;
    overflow: hidden;
    padding: 5px 0;
    width: 150px;
    display: none;
    text-align: left;
}

.input-phone-format-list span {
    display: block;
    padding: 3px 15px;
    cursor: pointer;
}

.input-phone-format-list span:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
}

.input-phone-format-change img {
    width: 20px;
}

.input-phone-format-list img {
    width: 20px;
    margin-right: 5px;
    display: inline-block;
}

.chat-dialog-attach-input {
    display: none;
}

.chat-dialog-attach-change svg:hover,
.chat-dialog-audio-start svg:hover,
.chat-dialog-audio-send svg:hover,
.chat-dialog-audio-cancel svg:hover,
.chat-dialog-text-send svg:hover {
    cursor: pointer;
    color: #f95146 !important;
}

.user-review-item-attach a {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    overflow: hidden;
    display: inline-block;
    margin-right: 5px;
    margin-top: 5px;
}

.dialog-content-attach {
    overflow: hidden;
    width: 100%;
    margin-top: 15px;
    border-radius: 10px;
    position: relative;
}

.dialog-content-attach img {
    width: 100%;
}

.module-chat-dialog-footer-box1 {
    height: 100%;
    padding: 10px;
}

.module-chat-dialog-footer-box2 {
    display: none;
    padding: 10px;
    height: 90px;
}

.audio-column {
    width: 2px;
    margin: 3px;
    display: inline-block;
    background: linear-gradient(94.43deg, #02AAEC 5.35%, #43E9E4 121.08%);
    z-index: 2;
}

.chat-dialog-audio-flex-box {
    height: 100%;
}

.chat-dialog-audio-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    margin-top: 20px;
    position: relative;
}

.audio-column-line {
    height: 1px;
    background: #c0eaf4;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    z-index: 1;
}

.chat-dialog-audio-flex-box2 {
    text-align: center;
    justify-content: center;
    flex-grow: 1;
    overflow: hidden;
}

.dialog-content-attach-voice {
    margin-top: 10px;
}

.catalog-count-ad {
    color: #bbbbbb;
    font-weight: 400;
    font-size: 25px;
}

.shop-item-card-list-logo-bg {
    padding: 15px 0;
    border-radius: 10px;
    overflow: hidden;
}

.search-store-offers-title {
    padding: 0 20px;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 10px;
}

.floating-link {
    position: fixed;
    bottom: 20px;
    left: 10px;
    right: 10px;
    z-index: 20;
    text-align: center;
}

.floating-link a,
.floating-link span {
    display: inline-flex;
    border-radius: 25px;
    padding: 0 35px;
    font-size: 14px;
    height: 45px;
    align-items: center;
    justify-content: center;
}

.floating-link a:hover,
.floating-link span:hover {
    opacity: 0.9;
    text-decoration: none;
    cursor: pointer;
}

.row-custom {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}

.flex-box-custom1 {
    margin-right: 10px;
}

.flex-box-custom2 {
    margin-right: 10px;
}

.flex-box-custom3 {
    flex-grow: 1;
}

.shop-item-card-list-content {
    padding-left: 15px;
}

.shop-category-list>ul ul {
    display: none;
}

*::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #f7f8fa;
}

*::-webkit-scrollbar-thumb {
    background: #d4d6db;
}

.chat-dialog-text-send {
    display: none;
}

.player-voice {
    display: flex;
    flex-grow: 1;
    height: 24px;
}

.player-voice-progress {
    flex-grow: 1;
    margin: 0 15px;
    display: flex;
    align-items: center;
}

.player-voice-progress-bg {
    width: 100%;
    display: block;
    background-color: #dfe6f3;
    height: 4px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.player-voice-progress-track {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    background-color: #c7c5c5;
    cursor: pointer;
}

.player-voice-time span {
    font-size: 12px;
    color: rgb(133, 133, 133);
}

.player-voice-action-play span,
.player-voice-action-stop span {
    display: block;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background: #007bff;
    color: white;
}

.module-chat-dialog-footer-box2 audio {
    display: none;
}

.player-voice-action-stop {
    display: none;
}

.modal-complaint-notification {
    display: none;
}

.modal-chat-user-content {
    height: 600px;
    overflow: hidden;
}

.dropdown-box-list-nested {
    display: none;
}

.width-auto {
    width: auto !important;
}

.mobile-fixed-menu-user-link i {
    margin-left: 7px;
}

.spinner-load-process {
    margin-right: 5px;
}

.modal-shop-edit-form {
    position: relative;
}

.modal-shop-edit-button {
    position: sticky;
    bottom: 15px;
}

.user-podtitle {
    font-size: 21px;
    font-weight: 500;
}

.user-tariff-box-item {
    border-radius: 10px;
    border: 2px solid #f7f8fa;
    padding: 25px;
    cursor: pointer;
    min-width: 305px;
}

.user-tariff-box-item:hover {
    border: 2px solid #007bff;
}

.user-tariff-box-item.active {
    border: 2px solid #007bff;
}

.user-tariff-box-item ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.user-tariff-box-item li {
    margin: 5px 0;
}

.user-tariff-box-item h5 {
    font-weight: 600;
}

.user-tariff-package-box-item-service {}

.user-tariff-package-box-item {
    background-color: #f7f8fa;
    padding: 25px;
    border-radius: 10px;
    margin: 10px 0;
}

.user-tariff-package-box-item p {
    margin: 0;
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 15px;
}

.user-tariff-package-box-item select {
    border-radius: 10px;
    height: 32px;
    border: 1px solid rgb(235, 235, 235);
    padding: 4px 11px;
    outline: none;
    min-width: 80px;
}

.settings-tariff-sidebar {
    position: relative;
    height: 100%;
    margin-bottom: 35px;
}

.settings-tariff-sidebar-calc {
    position: sticky;
    top: 15px;
    background-color: #f7f8fa;
    padding: 20px;
    border-radius: 10px;
}

.settings-tariff-sidebar-calc h5 {
    font-weight: 600;
    margin-bottom: 20px;
}

.settings-tariff-sidebar-calc-item {
    display: flex;
    margin: 5px 0;
    font-weight: 500;
}

.settings-tariff-sidebar-calc-item1 {
    flex-grow: 1;
}

.settings-tariff-sidebar-calc-item2 {
    justify-content: right;
}

.settings-tariff-sidebar-calc hr {
    background-color: #f1f1f1;
    color: #f1f1f1;
}

.user-tariff-box-item li {
    display: flex;
    font-size: 15px;
}

.user-tariff-box-item-service-name {
    flex-grow: 1;
    margin-left: 10px;
}

.user-tariff-box-item-service-icon i {
    font-size: 20px;
    color: rgb(57, 178, 0);
}

.tariff_oldPrice {
    margin-left: 10px;
    text-decoration: line-through;
    color: red;
}

.user-tariff-box-item p {
    font-size: 15px;
}

.user-tariff-box-item-service-name i {
    font-size: 20px;
    color: #007bff;
    cursor: pointer;
    margin-left: 5px;
}

.settings-tariff-sidebar-calc {
    display: none;
}

.user-tariff-box-item-gray {
    background-color: #f7f8fa;
}

.profile-button-tariff-box {
    text-align: right;
}

.adapt-align-right {
    text-align: right;
}

.profile-tariff-features-list span {
    display: inline-block;
    border-radius: 25px;
    padding: 7px 15px;
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: #f7f8fa;
}

.profile-tariff-features-list span.line-through {
    text-decoration: line-through;
}

.auth-block-tabs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.auth-block-tabs span {
    text-align: center;
    display: block;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    padding-bottom: 7px;
    color: rgb(115, 125, 155);
}

.auth-block-tabs span.active {
    color: #53b374;
    border-bottom: 3px solid #53b374;
}

.auth-block-tabs span:hover {
    color: #53b374;
}

.auth-block-tab-2,
.auth-block-tab-forgot,
.create-save-phone-cancel {
    display: none;
}

.ads-create-main-data-user-options-phone-buttons {
    margin-left: 10px;
}

.a-prev-hover i {
    font-size: 30px !important;
}

.a-prev-hover:hover {
    color: #0066f5 !important;
}

.uni-select-list-search input {
    background-color: white !important;
}

.table-borderless thead {
    background-color: #f7f8fa;
}

.table-borderless th {
    color: #212529;
    font-weight: 500;
}

.user-list-ad-img {
    position: relative;
    height: 100%;
    overflow: hidden;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    max-height: 165px;
}

.user-list-ad-item {
    position: relative;
    background-color: #f7f8fa;
    border-radius: 10px;
    margin-bottom: 10px;
}

.user-list-ad-info {
    position: relative;
    padding: 10px 15px;
    min-height: 150px;
    max-height: 220px;
}

.user-list-ad-info a {
    font-size: 15px;
    display: block;
    font-weight: 500;
}

.user-list-ad-info-view {
    display: flex;
    justify-content: space-between;
}

.user-list-ad-info-view>div {
    width: 100%;
}

.user-list-ad-info-view-icon {
    display: inline-block;
}

.user-list-ad-info-geo-cat {
    margin-top: 5px;
    font-size: 13px;
    color: rgb(133, 133, 133);
    margin-bottom: 5px;
}

.user-list-ad-info-menu {
    position: relative;
    text-align: right;
}

.user-list-ad-info-menu>svg {
    cursor: pointer;
}

.user-list-ad-info-menu-list {
    position: absolute;
    top: 25px;
    right: 0px;
    background-color: white;
    border-radius: 10px;
    min-width: 200px;
    box-shadow: 0 6px 32px #5e5c5c24;
    overflow: hidden;
    border: 1px solid transparent;
    z-index: 3;
    display: none;
    text-align: left;
}

.user-list-ad-info-menu-list>span,
.user-list-ad-info-menu-list>a {
    display: block;
    padding: 8px 20px;
    color: black;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
}

.user-list-ad-info-menu-list>span:hover,
.user-list-ad-info-menu-list>a:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
    text-decoration: none;
}

.user-list-ad-info-status {
    margin-bottom: 5px;
}

.user-list-ad-info-label {
    margin-top: 15px;
    text-align: right;
}

.user-list-ad-info-label>div {
    display: inline-block;
}

.user-list-ad-info-label i {
    font-size: 16px;
}

.user-list-ad-info-label span {
    display: inline-flex;
    margin-right: 5px;
    font-size: 15px;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    margin-bottom: 5px;
    align-items: center;
    justify-content: center;
}

.user-list-ad-info-label-renewal i {
    font-size: 22px;
}

.user-list-ad-info-label-renewal {
    margin-right: 10px;
}

.user-menu-tab-content .item-grid-favorite i.ion-ios-heart-outline {
    color: #d7d7d7 !important;
}

.profile-statistics-user-item {
    border-radius: 10px;
    border: 1px solid #f7f8fa;
    padding: 15px;
    text-align: center;
    margin-bottom: 15px;
}

.profile-statistics-user-item-avatar {
    position: relative;
    border-radius: 50px;
    overflow: hidden;
    display: inline-block;
    height: 60px;
    width: 60px;
}

.profile-statistics-user-item-name {
    margin-top: 5px;
}

.change-statistics-filter-date {
    height: 42px;
    border-radius: 10px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #f7f8fa;
    border: 1px solid #f7f8fa;
    position: relative;
}

.clear-statistics-filter-date {
    position: absolute;
    top: 11px;
    right: 11px;
    cursor: pointer;
    display: inline-block;
}

.clear-statistics-filter-date i {
    font-size: 19px;
}

.change-statistics-filter-date span {
    display: block;
    width: 100%;
    margin-right: 20px;
}

.button-shop-edit-mobile {
    display: none;
}

.custom-selectpicker {
    background-color: #f7f8fa;
    border: 1px solid #f7f8fa;
    border-radius: 10px;
    padding: 7px 10px;
}

.module-chat-users-support-item {
    border-radius: 15px;
    margin: 15px;
    background: rgba(0, 0, 0, 0) linear-gradient(210deg, rgb(215, 245, 255), rgb(236, 239, 255)) repeat scroll 0% 0%;
    position: relative;
}

.module-chat-users-support-item .module-chat-users-img {
    background-color: transparent !important;
}

.chat-dialog-text-flex-box-locked {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
}

.module-chat-users-support-item .module-chat-users-count-msg {
    position: absolute;
    right: -20px;
    top: -20px;
}

.map-search-sidebar {
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    z-index: 10;
    width: 500px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 3px 1px rgb(0 0 0 / 20%);
    display: none;
}

.map-search-offer {
    display: none;
}

.map-search-offer-item .item-grid-img,
.map-search-offer-item .item-grid-img a {
    height: 250px !important;
}

.map-search-offer-item .ad-gallery-hover-slider span {
    height: 248px !important;
}

.map-search-offer-item .item-grid {
    margin-top: 0px !important;
}

.map-label-count {
    border: 2px solid white;
    font-size: 10px;
    background-color: #F75059;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: white !important;
    margin-left: 5px;
    position: absolute;
    top: -4px;
    right: -4px;
}

.map-search-sidebar-mobile-open-toggle,
.map-search-sidebar-mobile-close-toggle {
    position: absolute;
    left: 50%;
    top: -28px;
    display: none;
    cursor: pointer;
    margin-left: -12px;
}

.map-search-sidebar-mobile-open-toggle i,
.map-search-sidebar-mobile-close-toggle i {
    font-size: 25px;
    color: black;
}

.catalog-list-options-items {
    max-height: 350px;
    overflow: auto;
    margin-left: -5px;
    padding-left: 5px;
}

.ads-create-main-data-box-item-flex {
    display: flex;
    flex-grow: 1;
    align-items: center;
}

.ads-create-main-data-box-item-flex1 {
    margin-right: 10px;
}

.ads-create-main-data-box-item-flex2 {
    flex-grow: 1;
}

.adPrefixPriceFrom,
.adPrefixPriceMeasure {
    font-size: 16px;
}

.booking-additional-services-container,
.booking-conditions-container {
    margin-bottom: 5px;
}

.booking-additional-services-item,
.booking-conditions-item {
    margin-bottom: 15px;
}

.booking-additional-services-item-delete {
    cursor: pointer;
}

.booking-additional-services-item-delete i {
    font-size: 22px;
}

.btn-custom-mini-icon i {
    font-size: 16px;
}

.booking-additional-services-item-row {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;
}

.booking-additional-services-item-row1 {
    margin-right: 10px;
    flex-grow: 1;
}

.booking-additional-services-item-row2 {
    margin-right: 10px;
}

.booking-additional-services-item-delete i:hover {
    color: red;
}

.booking-additional-services-item-add {
    margin-left: 10px;
}

.box-save-auth {
    text-align: left;
}

.mb0 {
    margin-bottom: 0px;
}

.booking-additional-services-box-item {
    margin-bottom: 7px;
}

.booking-change-date-box p {
    margin-top: 0;
    margin-bottom: 10px;
}

.btn-custom-subtitle1 {
    font-size: 12px;
    display: block;
}

.modal-booking-box-total {
    text-align: right;
}

.modal-booking-box-total-title1 {
    font-size: 13px;
    display: block;
}

.modal-booking-box-total-title2 {
    font-size: 17px;
    font-weight: bold;
    display: block;
}

.modal-booking-errors {
    background-color: #ffebeb;
    padding: 15px;
    border-radius: 5px;
    margin-top: 15px;
    display: none;
}

.ui-datepicker {
    padding: 10px !important;
}

.ui-widget-header {
    background-color: #f7f8fa !important;
    border: 1px solid #f7f8fa !important;
}

.ui-datepicker-calendar td {
    padding: 3px !important;
}

.ui-state-default {
    padding: 3px !important;
    background-color: #f7f8fa !important;
    border: 1px solid #f7f8fa !important;
    border-radius: 3px !important;
}

.ui-state-active {
    background-color: rgb(232, 237, 254) !important;
    border: 1px solid rgb(232, 237, 254) !important;
    color: black !important;
}

.ui-state-default:hover {
    background-color: rgb(232, 237, 254) !important;
    border: 1px solid rgb(232, 237, 254) !important;
}

.ui-widget.ui-widget-content {
    border: 1px solid #f7f8fa !important;
    border-radius: 5px !important;
    -webkit-box-shadow: 0 11px 38px rgb(80 83 97 / 19%) !important;
}

.ui-datepicker-next-hover,
.ui-datepicker-prev-hover {
    background-color: transparent !important;
    border: none !important;
    cursor: pointer;
    top: 2px !important;
}

.ui-state-disabled .ui-state-default {
    text-decoration: line-through !important;
}

.load-content-index-container .preload {
    padding: 50px 0;
}

.order-booking-status-label-0 {
    background-color: #ffc107;
    color: #212529;
}

.order-booking-status-label-1 {
    background-color: #28a745;
    color: white;
}

.order-booking-status-label-2 {
    background-color: #dc3545;
    color: white;
}

.shop-catalog-subcategory {
    visibility: hidden;
    padding: 0 0px !important;
}

.swal-overlay--show-modal .swal-modal {
    will-change: initial;
}